.lucky {
    .phone-wrap {
        width: 375px;
        height: 667px;
        background: #f5f5f5;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;

        .phone-title {
            width: 375px;
            height: 65px;
            background-image: url('https://img.cxkoo.com/chengxuan/1/2022-07-05/fb89e72ff52293a7fbd3a47fdcf1e47e.png');
            background-size: cover;
            color: #000;
            font-size: 20px;
            text-align: center;
            box-sizing: border-box;
            padding-top: 24px;
        }

        .phone-content {
            min-height: 602px;
            box-sizing: border-box;
            padding: 195px 0 15px;
            background-color: #FFDCB6;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/64dad0836dec8f7dccbadeb06894c3b7.png');
            position: relative;

            .activity_desc {
                padding: 5px;
                font-size: 12px;
                color: #fff;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 5px 0px 0px 5px;
                position: absolute;
                right: 0;
                top: 115px;

            }

            .activity_time {
                font-size: 13px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 18.5px;
                text-align: center;
                margin-bottom: 10px;
            }
        }

        .grid_wrap {
            width: 366px;
            height: 438.5px;
            background-size: 100% 100%;
            background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/290937bff54209f1a018379fdd2e4093.png');
            margin: 0 auto;
            position: relative;

            .grid_box {
                width: 250px;
                height: 250px;
                position: absolute;
                top: 25px;
                left: 60px;
            }

            .points {
                position: absolute;
                left: 101px;
                bottom: 29px;

                .txt1 {
                    color: #8E3C01;
                    font-weight: bold;
                    line-height: 0.98;
                }

                .txt2 {
                    color: #CA731C;
                }
            }

            .prize {
                position: absolute;
                left: 256px;
                bottom: 29px;

                .txt1 {
                    color: #8E3C01;
                    display: flex;
                    align-items: center;
                    line-height: 0.98;

                    .you {
                        width: 15px;
                        height: 15px;
                        background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/40af8b2f971ead409a07e61034d05fb5.png');
                        display: inline-block;
                        background-size: cover;
                        background-position: 50% 50%;
                        vertical-align: middle;
                        margin-left: 8px;
                    }
                }

                .txt2 {
                    color: #CA731C;
                }
            }


            .residue {
                position: absolute;
                left: 275px;
                bottom: 85px;
                display: flex;
                align-items: center;

                .label {
                    color: #D55817;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.2;
                }

                .num {
                    width: 25px;
                    height: 25px;
                    background: #fff;
                    color: #FE5F00;
                    text-align: center;
                    line-height: 25px;
                    font-size: 17px;
                    margin-left: 8px;
                }
            }
        }

        .wheel_wrap {
            width: 364.5px;
            height: 453.5px;
            background-size: 100% 100%;
            background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/71aa292debcd8307e6ee86597cebcb67.png');
            margin: 0 auto;
            position: relative;

            .wheel_box {
                width: 259px;
                height: 259px;
                position: absolute;
                top: 44.5px;
                left: 60px;
            }

            .points {
                position: absolute;
                left: 99px;
                bottom: 40px;

                .txt1 {
                    color: #8E3C01;
                    font-weight: bold;
                    line-height: 0.98;
                }

                .txt2 {
                    color: #CA731C;
                }
            }

            .prize {
                position: absolute;
                left: 254px;
                bottom: 40px;

                .txt1 {
                    color: #8E3C01;
                    display: flex;
                    align-items: center;
                    line-height: 0.98;

                    .you {
                        width: 15px;
                        height: 15px;
                        background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/40af8b2f971ead409a07e61034d05fb5.png');
                        display: inline-block;
                        background-size: cover;
                        background-position: 50% 50%;
                        vertical-align: middle;
                        margin-left: 8px;
                    }
                }

                .txt2 {
                    color: #CA731C;
                }
            }


            .residue {
                position: absolute;
                left: 275px;
                bottom: 97px;
                display: flex;
                align-items: center;

                .label {
                    color: #D55817;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.2;
                }

                .num {
                    width: 25px;
                    height: 25px;
                    background: #fff;
                    color: #FE5F00;
                    text-align: center;
                    line-height: 25px;
                    font-size: 17px;
                    margin-left: 8px;
                }
            }
        }

        .task_wrap {
            padding: 0 15px;
            background: #FEF5D9;
            margin-top: 15px;
            border-radius: 20px;
        
            .head {
                width: 201px;
                height: 35px;
                background-size: 100% 100%;
                background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/9090ff5b4d1bb9e8c1eb730f04244c61.png');
                margin: 0 auto;
        
                .head_txt {
                    width: 100%;
                    height: 35px;
                    font-size: 15px;
                    font-weight: 600;
                    color: #000000;
                    line-height: 35px;
                    text-align: center;
                    background: linear-gradient(180deg, #FF7A0C 0%, #C14C00 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        
            .task {
                display: flex;
        
                .left {
                    width: 55px;
                    height: 55px;
                    margin-right: 10px;
                    background-size: 100% 100%;
                    margin-top: 15px;
        
                    &.t1 {
                        background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/dce2156c62d926f54571252f1892f7a4.png');
                    }
        
                    &.t2 {
                        background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/67e4c3c978016500cad033ae3fdd1fb9.png');
                    }
        
                    &.t3 {
                        background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/42038855c2dfc17202d54149339269f5.png');
                    }
                }
        
                .right {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding: 17px 0;
                    border-bottom: 1px solid #FCD885;
        
                    .desc {
                        flex: 1;
                        height: 46px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
        
                        .txt1 {
                            color: #8E3C01;
                            font-size: 15px;
                            font-weight: 600;
                        }
        
                        .txt2 {
                            font-size: 13px;
                            color: #CA731C;
                        }
                    }
        
                    .btn {
                        padding: 4px 12.5px;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 600;
                        border-radius: 15px;
                        background: linear-gradient(90deg, #FDA429 0%, #FE3069 48%, #FE2B3B 100%);
                        margin-left: 4px;
                    }
        
                }
        
                &:last-child {
                    .right {
                        border: unset;
                    }
        
                }
            }

            
        }
        .winning_wrap {
            padding: 0 15px;
            background: #FEF5D9;
            margin-top: 15px;
            border-radius: 20px;
        
            .head {
                width: 201px;
                height: 35px;
                background-size: 100% 100%;
                background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-05/9090ff5b4d1bb9e8c1eb730f04244c61.png');
                margin: 0 auto;
        
                .head_txt {
                    width: 100%;
                    height: 35px;
                    font-size: 15px;
                    font-weight: 600;
                    color: #000000;
                    line-height: 35px;
                    text-align: center;
                    background: linear-gradient(180deg, #FF7A0C 0%, #C14C00 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        
            .title {
                font-size:13px;
                color: #CA731C;
                margin-top: 10px;
                text-align: center;
                white-space: pre;
            }
        
            .winning {
                padding: 10px 0;
                display: flex;
                border-bottom: 1px solid #FCD885;
        
                &:last-of-type {
                    border: unset;
                }
        
                .left {
                    width:44px;
                    height: 44px;
                    border-radius: 50%;
                    margin-right: 10px;

                    img {
                        width:44px;
                        height: 44px;
                        border-radius: 50%;
                    }
                }
        
                .right {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
        
                    .nickname {
                        font-size: 14px;
                        color: #8E3C01;
                    }
        
                    .time {
                        font-size: 12px;
                        color: #CA731C;
                        margin-top: 8rpx;
                    }
        
                    .prize {
                        color: #FF1730;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        
            .more {
                padding: 19px;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                color: #871D01;
                border-top: 1px solid #FCD885;
        
                .you {
                    width: 6px;
                    height: 10px;
                    background-image: url('https://img.cxkoo.com/chengxuan/1/2023-05-06/b6c69389874faa8c3d7a94fc7bd82c8d.png');
                    display: inline-block;
                    background-size: cover;
                    background-position: 50% 50%;
                    vertical-align: middle;
                    margin-left: 8px;
                }
            }
        }

    }

    .ctrl-wrap {
        width: calc(100% - 395px);

        .example_img {
            // width: 200px;
        }
    }
}