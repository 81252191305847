.app-recommend-item{
    width: 25.2vw;
    min-width: 355px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #D8D5FF;
    border-radius: 10px;
    overflow: hidden;
    height: 7.3vw;
    min-height: 109px;
    cursor: pointer;
    .left{
        width: 39.6%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        flex-shrink: 0;
        margin-right: 15px;
    }
    .right{
        box-sizing: border-box;
        padding-right: 15px;
        .title{
            font-size: 16px;
            color: #000;
            line-height: 24px;
            font-weight: bold;
        }
        .desc{
            font-size: 12px;
            color: #818181;
            line-height: 20px;
            margin-top: 5px;
        }

    }
}
.cx-app-item{
    display: flex;
    width: 49%;
    justify-content: flex-start;
    align-items: center;
    height: 90px;
    border: 1px solid #D8D5FF;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s;
    margin-bottom: 25px;
    .left{
        width: 69px;
        height: 69px;
        background: #F6F5FF;
        border-radius: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 16px;
        transition: all 0.2s;
        img{
            width: 38px;
        }
    }
    .right{
        .txt1{
            font-size: 16px;
            color: #000;
            font-weight: bold;
            line-height: 24px;
            font-weight: bold;
        }
        .txt2{
            font-size: 12px;
            color: #818181;
            line-height: 20px;
            margin-top: 5px;
        }
    }
    &:hover{
        background-color: #F6F5FF;
        .left{
            background-color: #fff;
        }
    }
}
