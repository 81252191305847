$inputWidth:300px;
$inputAddonWidth:263px;
$inputWidth-s:200px;
$inputWidth-sm:250px;
$inputWidth-xl:350px;
$inputWidth-xxl:400px;
@import "../mixins.scss";
.ec-panel {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}
.ec-panel .ec-panel-header {
    position: relative;
    padding: 6px 14px;
    background-color: #fafafa;
}
.ec-panel .ec-panel-header + .ec-panel-body {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.ec-panel .ec-panel-body {
    padding: 14px;
}
.ec-panel .ec-panel-body + .ec-panel-body {
    border-top: 1px solid #d9d9d9;
}
.ec-panel .ec-action {
    position: absolute;
    top: 50%;
    right: 14px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.ec-input-w300{
    .ant-input:not(.ant-calendar-picker-input){
        max-width: $inputWidth;
    }
    .ant-input-group-addon + .ant-input{
        width: $inputAddonWidth;
    }
    .addon-after-input .ant-input{
        width: 100%;
        //width: 100px;
    }
    .sm-input{
        width: $inputWidth-sm;
    }
    .s-input{
        width: $inputWidth-s;
    }
    .ant-input-suffix{
        left: $inputWidth - 40px;
        font-size: 12px;
        color: #999;
    }
}
.delete-icon{
    color: #c8c8c8;
    cursor: pointer;
}
.ant-table-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
}
.batch-setting-title {
    margin-right: 10px;
    line-height: 40px;
}
.batch-setting-title::after {
    content: ':';
}
.btn-batch-setting {
    line-height: 40px;
}
.btn-batch-setting + .btn-batch-setting {
    margin-left: 20px;
}
.tag-cont{
    .tag{
        height: 24px;
        padding: 0 7px;
        font-size: 14px;
        border-radius: 4px;
        box-sizing: border-box;
        display: inline-block;
        margin-left: 10px;
        border: 1px solid #d9d9d9;
        text-align: center;
        line-height: 22px;
        background-color: #f7f7f7;
    }
    .tagclose{
        font-size: 10px;
        margin-left: 3px;
        cursor: pointer;
    }
    .taginput{
        margin-left: 10px;
        width: 100px;
        display: inline-block;
    }
}
.icon-question-balance{
    font-size: 14px;
    line-height: 30px;
    margin-left: 3px;
    cursor: pointer;
    color: $BaseColor;
}
.rule-bg{
    padding: 10px;
    background-color: #f5f5f5;
    width: 600px;
    border-radius: 2px;
}
.pdf-file-input{
    position: absolute;
    top: 0;
    left: 10px;
    right: 0;
    bottom: 0;
    width: 80px;
    height: 30px;
    direction: rtl;
    font-size: 23px;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

