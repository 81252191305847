table {
    border-collapse: collapse;
    border-spacing: 0;
}
.cxtables {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #e7e7eb;
}
.cxtables td {
    padding: 8px 10px;
    font-size: 12px;
    line-height: 1.5em;
    border-bottom: 1px solid #eee;
    color: #333;
    .td-item{
        padding:8px 10px;
        padding-right: 5px;
    }
    .td-price{
        height: 100%;
        text-align: left;
        padding:8px 10px;
        padding-left: 5px;

    }
    &.noPadding{
        padding: 8px 0px;
    }
}
.bd-bottom{
    border-bottom: 1px solid #eee;
    &:last-child{
        border-bottom: 0px;
    }
}
.bd-right{
    border-right: 1px solid #eee;
    &:last-child{
        border-right: 0px;
    }
}

.cxtables tbody tr:hover td {
    background-color: #F2F8FC
}

.cxtables tbody tr td {
    border-right: 1px solid #eee;
}

.cxtables td.left {
    text-align: left
}

.cxtables td.right {
    text-align: right
}

.cxtables td.center {
    text-align: center
}

.cxtables thead td {
    font-weight: 700;
    background: #f5f5f5;
    color: #555;
    border-left: none;
    border-right: none
}

.cxtables thead tr td:first-child {
    border-left: 1px solid #e7e7eb
}

.cxtables thead tr td:last-child {
    border-right: 1px solid #e7e7eb
}

.cxtables .ant-input-group-addon, .cxtables .ant-input{
    font-size: 12px;
    color: #333;
}

//场馆预订

.cxtables .court-thead td {
    font-weight: 700;
    background: #f5f5f5;
    color: #555;
    border-left: 1px solid #eee;
    border-right: none
}

.cxtables .court-tbody td{
    padding: 0px;
}
.cxtables .court-tbody .fake-td{
    padding: 25px 10px;
    text-align: center;
    &.status_1{
        background-color: #5E4CFF;
        color: #fff;
    }
    &.status_2{
        background-color: orange;
        color: #fff;
    }
    &.status_3{
        background-color: #52c41a;
        color: #fff;
    }
}
