.colorPanel{
    padding: 4px;
    background: rgb(255, 255, 255);
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    position: relative;
    .colorInner{
        width: 80px;
        height: 16px;
        border-radius: 2px;
        background-color: #fff;
        box-sizing: border-box;
        position: relative;
        z-index: 100;
    }
}
.sketch-picker{
    position: absolute;
    line-height: initial;
    z-index: 99999;
    top: 30px;
}
.colorMask{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0px;
    top: 0px;

}
