$pageW : 750;

/*common*/
.modulePadding {
    position: relative;

    .del-module {
        cursor: pointer;
        width: 16px;
        height: 16px;
        font-size: 16px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        color: $BaseColor;
        display: none;
        background-color: #fff;
    }

    &:hover {
        cursor: move;

        &::before {
            display: block;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            border: 1px dashed $BaseColor;
            box-sizing: border-box;
            z-index: 2;
        }

        .del-module {
            display: block;
        }
    }

    &.selected {
        cursor: move;

        &::before {
            display: block;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            border: 1px dashed $BaseColor;
            box-sizing: border-box;
            z-index: 2;
        }

        .del-module {
            display: block;
        }
    }
}


/*公告*/
.notice-bar {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    color: #ed6a0c;
    font-size: 14px;
    line-height: 24px;
    background-color: #fffbe8;

    .notice-bar--icon {
        min-width: 22px;
    }

    .notice-bar--txt {
        white-space: nowrap;
        padding-left: 246px;
        animation: van-notice-bar-play-infinite linear infinite both;
        animation-delay: 0s;
        animation-duration: 10.8s;
    }

    .margueen {
        position: relative;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        height: 24px;
        overflow: hidden;

    }
}

@keyframes van-notice-bar-play-infinite {
    to {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

}

/*图片*/
.img-ad {
    box-sizing: border-box;
}

/* 废弃 */
.diy-imgNav {
    .imgNav-img {
        position: relative;
        display: block;
        float: left;
        width: percentage(181/$pageW);
        padding-top: percentage(181/$pageW);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-right: percentage(8/$pageW);

        &:last-child {
            margin-right: 0;
        }

        &.lazyload-ph {
            background-size: contain;
        }

        .info {
            position: absolute;
            left: 5%;
            bottom: 5%;
            width: 90%;
            padding: 1px 5px;
            background-color: #FE9303;
            border-radius: 15px;
            color: #fff;
            font-size: 10px;
            z-index: 1;
            text-align: center;
            white-space: nowrap;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &.col1 {
        .imgNav-img {
            width: percentage($pageW/$pageW);
            padding-top: percentage($pageW/$pageW);

            .info {
                bottom: 2%;
                font-size: 14px;
                padding: 5px;
            }

            .info.middle {
                bottom: 50%;
                margin-bottom: -12px;
            }

            .info.top {
                top: 2%;
                bottom: initial;
            }
        }
    }

    &.col2 {
        .imgNav-img {
            width: percentage(371/$pageW);
            padding-top: percentage(371/$pageW);

            .info {
                padding: 2px 5px;
                font-size: 12px;
            }

            .info.middle {
                bottom: 50%;
                margin-bottom: -8px;
            }

            .info.top {
                top: 2%;
                bottom: initial;
            }
        }
    }

    &.col3 {
        .imgNav-img {
            width: percentage(244/$pageW);
            padding-top: percentage(244/$pageW);

            .info {
                padding: 2px 5px;
            }

            .info.middle {
                bottom: 50%;
                margin-bottom: -8px;
            }

            .info.top {
                top: 2%;
                bottom: initial;
            }
        }
    }

    &.col4 {
        .imgNav-img {
            width: percentage(180/$pageW);
            padding-top: percentage(180/$pageW);

            .info {
                bottom: 2%;
                font-size: 12px;
                padding: 5px;
            }

            .info.middle {
                bottom: 50%;
                margin-bottom: -11px;
            }

            .info.top {
                top: 2%;
                bottom: initial;
            }
        }
    }

    &.col5 {
        .imgNav-img {
            width: percentage(143/$pageW);
            padding-top: percentage(143/$pageW);

            .info {
                bottom: 2%;
                font-size: 10px;
                padding: 5px;
            }

            .info.middle {
                bottom: 50%;
                margin-bottom: -11px;
            }

            .info.top {
                top: 2%;
                bottom: initial;
            }
        }
    }
}

/* 图文导航 */
.img-nav-swiper {
    background-color: #fff;
    position: relative;

    &.style1 {
        .swiper {
            height: 100px;
        }
    }

    &.style2 {
        .swiper {
            height: 190px;
        }
    }

    .indicator {
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 7px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dots {
            width: 7px;
            height: 7px;
            background-color: rgba(233, 233, 233, 1);
            border-radius: 50%;
            margin-right: 7px;

            &:last-child {
                margin-right: 0px;
            }

            &.active {
                background-color: rgba(72, 72, 72, 1);
            }

        }
    }
}

.img-nav-cont {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 10px 10px 0px 10px;

    .img-nav-item {
        width: 62px;
        flex-shrink: 0;
        margin-right: 10px;
        margin-bottom: 5px;

        .nav-item-img {
            width: 62px;
            height: 62px;
            background-size: cover;
            background-position: 50%;
            margin-bottom: 5px;
            background-color: #f5f5f5;
        }

        .nav-item-txt {
            font-size: 12px;
            line-height: 17px;
            color: #484848;
            width: 100%;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:nth-of-type(5n) {
            margin-right: 0px;
        }
    }

    &.space-around {
        justify-content: space-around;
    }
}


/*分割线*/
.diy-line {
    width: 100%;
    border-bottom: 1px solid #ddd;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;

    &.style0 {
        border-style: solid;
    }

    &.style1 {
        border-style: dashed;
    }
}

/*轮播图*/
.members_flash {
    width: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

.members_flash .swiper-radius {
    border-radius: 10px;
}

.members_flash ul li {
    float: left;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.members_flash_time {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    text-align: center
}

.members_flash_time span {
    display: inline-block;
    margin: 0 2px;
    width: 8px;
    height: 8px;
    background: rgba(203, 203, 203, .5);
    border-radius: 10px;
    overflow: hidden
}

.members_flash_time span.cur {
    background: $BaseColor;
}

.swiper-style2 {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    padding-bottom: 5px;

    .member-info {
        width: 342px;
        height: 91px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 0px auto;
        margin-top: -32px;
        position: relative;
        z-index: 2;
        box-sizing: border-box;
        padding: 0px 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .avatar {
            width: 50px;
            height: 50px;
            background-color: #e5e5e5;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 15px;
            background-size: cover;
            background-position: 50%;
        }

        .desc {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .txt1 {
                font-size: 17px;
                color: #000;
                line-height: 23px;
                margin-bottom: 7px;
                font-weight: bold;
            }

            .txt2 {
                font-size: 12px;
                color: #898989;
                line-height: 15px;

            }
        }
    }
}

.swiper-style3 {
    .diy-store-info {
        width: 100%;
        box-sizing: border-box;
        padding: 0px 10px;
        background-color: #4F0201;
        color: #fff;

        .store-basic {
            width: 100%;
            box-sizing: border-box;
            padding: 15px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .logo {
                width: 74px;
                height: 74px;
                border-radius: 50%;
                background-color: #e5e5e5;
                background-size: cover;
                background-position: 50%;
                flex-shrink: 0;
                margin-right: 17px;
            }

            .store-name {
                font-size: 18px;
                line-height: 25px;
                font-weight: bold;

            }
        }

        .store-address {
            width: 100%;
            box-sizing: border-box;
            padding: 15px 0px;
            border-top: 1px solid rgba(255, 255, 255, 0.5);
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .left {
                display: flex;
                flex: 1;
                flex-direction: column;
                position: relative;
                padding-right: 15px;
                font-size: 14px;
                line-height: 17px;
                justify-content: center;
                align-items: flex-start;

                .address {
                    margin-bottom: 5px;
                }

                .online {}

                &::after {
                    display: block;
                    content: '';
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    background-color: rgba(255, 255, 255, 0.5);

                }
            }

            .right {
                width: 50px;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-size: 12px;
                line-height: 17px;

                .tel-img {
                    width: 26px;
                    height: 26px;
                    background-size: cover;
                    background-position: 50%;

                }
            }

        }

        .store-online-tags {
            width: 100%;
            box-sizing: border-box;
            padding: 12px 0;
            border-top: 1px solid rgba(255, 255, 255, 0.5);

            .online {
                font-size: 14px;
            }
        }

        .store-tags {
            margin-top: 6px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .tag {
                height: 20px;
                box-sizing: border-box;
                border: 1px solid #fff;
                padding: 0px 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                margin-right: 7px;

                &:last-child {
                    margin-right: 0px;
                }
            }

        }
    }
}

/*标题文本*/
.diy-txt-container {
    font-size: 16px;
    line-height: 22px;
    padding: 0;

    .wrap {
        padding: 15px 10px;
    }

    .diy-txt-content {
        color: #333;
    }

    &.style0 {
        text-align: center;
    }

    &.style1 {
        text-align: left;
    }

    &.style2 {
        text-align: right;
    }
}

/*商品列表*/
/*样式1*/
.flowList {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    .column {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: calc(100%/2);
    }

    .column:nth-of-type(2n) {
        align-items: flex-end;
    }

    .flow-item {
        width: 173px;
        border-radius: 10px;
        background-color: #fff;
        margin-bottom: 8px;

        .item-img {
            width: 173px;
            height: 173px;
            background-color: #f5f5f5;
            background-size: cover;
            background-position: 50% 50%;
            border-radius: 3px 3px 0px 0px;
        }

        .item-desc {
            width: 100%;
            box-sizing: border-box;
            padding: 4px 7px 6px 7px;

            .title {
                font-size: 14px;
                line-height: 17px;
                font-weight: bold;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .sub-title {
                font-size: 12px;
                color: #999;
                margin-top: 2px;
                line-height: 15px;
            }

            .sale {
                font-size: 12px;
                color: #999;
            }

            .price-cart {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .price-group {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .price {
                    color: $primary-color;
                    font-size: 12px;
                    font-weight: bold;

                    span {
                        font-size: 17px;
                    }
                }

                .o-price {
                    font-size: 12px;
                    color: #999;
                    margin-left: 5px;
                    text-decoration: line-through;
                }

                .cart {
                    width: 28px;
                    height: 28px;
                    background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-09/6d3c8e682aeeab6a19c4c9275788a08a.png");
                    background-size: cover;
                }

                .cart-style2 {
                    width: 28px;
                    height: 28px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/9585a77f1dd9d74ebf6ce47ccbb4983b.png");
                    background-size: cover;
                }

                .cart-style3 {
                    width: 45px;
                    height: 22px;
                    background-color: $primary-color;
                    border-radius: 13px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    color: #fff;
                }

                .price-point {
                    color: $primary-color;
                    font-size: 14px;
                    font-weight: bold;
                }

                .price-btn {
                    font-size: 12px;
                    padding: 1px 10px;
                    color: #fff;
                    background: #f00;
                    border-radius: 20px;
                    flex-shrink: 0;
                }

            }
        }

        &.shadow {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }

        &.border {
            border: 1px solid #DEDEDE;
            box-sizing: content-box;
        }
    }
}

/*样式2*/
.item-column3 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0px 7px;

    .item-list {
        width: 116px;
        border-radius: 6px;
        background-color: #fff;
        margin-right: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;

        .top-img {
            width: 100%;
            height: 116px;
            border-radius: 3px 3px 0px 0px;
            background-color: #f5f5f5;
            background-size: cover;
            background-position: 50% 50%;

            &.noImg {
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/5b39b12285b4820bbd8940ed72fd7e79.png");
                background-size: 45px 39px;
                background-repeat: no-repeat;
            }

        }

        .item-desc {
            width: 100%;
            box-sizing: border-box;
            padding: 5px;

            .title {
                font-size: 14px;
                color: #000;
                line-height: 17px;
                height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-weight: bold;
            }

            .sub-title {
                font-size: 12px;
                color: #999;
                line-height: 16px;
                height: 16px;
                margin-top: 4px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .price-group {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;

                .price {
                    color: $primary-color;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 26px;

                    span {
                        font-size: 16px;
                    }
                }

                .cart {
                    width: 22px;
                    height: 22px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/38dd30c08ceeff81769f721a4a3a35a4.png");
                    background-size: cover;
                }

                .cart-style2 {
                    width: 22px;
                    height: 22px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/9585a77f1dd9d74ebf6ce47ccbb4983b.png");
                    background-size: cover;
                }

                .cart-style3 {
                    width: 38px;
                    height: 18px;
                    background-color: $primary-color;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #fff;
                }

                .price-btn {
                    font-size: 12px;
                    padding: 1px 8px;
                    color: #fff;
                    background: #f00;
                    border-radius: 20px;
                    flex-shrink: 0;
                }

            }
        }

        &:nth-of-type(3n) {
            margin-right: 0px;
        }

        &.shadow {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }

        &.border {
            border: 1px solid #DEDEDE;
        }

    }

}

/*样式3*/
.cx-item-style2 {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;

    .left {
        width: 137px;
        height: 137px;
        flex-shrink: 0;
        border-radius: 3px;
        background-color: #f5f5f5;
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
        margin-right: 7px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
    }

    .sold_out {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;

        image {
            width: 80px;
            height: 80px;
            position: relative;
            z-index: 2;
        }
    }

    .right {
        height: 137px;
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 7px;

        .title {
            font-size: 14px;
            color: #000;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-weight: bold;
        }

        .sub-title {
            font-size: 12px;
            color: #999;
            margin-top: 4px;
            line-height: 16px;
        }

        .right-bottom {
            width: 100%;
            position: absolute;
            left: 0px;
            bottom: 9px;
            box-sizing: border-box;
            padding-right: 5px;

            .sale {
                font-size: 12px;
                color: #999;
            }
        }

        .price-cart {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding-right: 5px;

            .price-group {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .price {
                color: $primary-color;
                font-size: 12px;
                font-weight: bold;

                text {
                    font-size: 17px;
                }
            }

            .o-price {
                font-size: 12px;
                color: #999;
                margin-left: 5px;
                text-decoration: line-through;
            }

            .cart {
                width: 28px;
                height: 28px;
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-09/6d3c8e682aeeab6a19c4c9275788a08a.png");
                background-size: cover;
            }

            .cart-style2 {
                width: 28px;
                height: 28px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/9585a77f1dd9d74ebf6ce47ccbb4983b.png");
                background-size: cover;
            }

            .cart-style3 {
                width: 45px;
                height: 22px;
                background-color: $primary-color;
                border-radius: 13px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                color: #fff;
            }

        }


    }

    &.disable {
        opacity: 0.6;
    }

    &.shadow {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    &.border {
        border: 1px solid #DEDEDE;
    }
}

/*样式4*/
.item-style4 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 7px;
    overflow-x: auto;

    .flex-cont {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    .item-list {
        width: 120px;
        flex-shrink: 0;
        border-radius: 6px;
        background-color: #fff;
        margin-right: 15px;
        margin-bottom: 0px;
        box-sizing: border-box;

        .top-img {
            width: 100%;
            height: 120px;
            border-radius: 3px 3px 0px 0px;
            background-color: #f5f5f5;
            background-size: cover;
            background-position: 50% 50%;

            &.noImg {
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/5b39b12285b4820bbd8940ed72fd7e79.png");
                background-size: 45px 39px;
                background-repeat: no-repeat;
            }

        }

        .item-desc {
            width: 100%;
            box-sizing: border-box;
            padding: 5px;

            .title {
                font-size: 14px;
                color: #000;
                line-height: 17px;
                height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-weight: bold;
            }

            .sub-title {
                font-size: 12px;
                color: #999;
                line-height: 16px;
                height: 16px;
                margin-top: 4px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .price-group {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;

                .price {
                    color: $primary-color;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 26px;

                    span {
                        font-size: 16px;
                    }
                }

                .cart {
                    width: 22px;
                    height: 22px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/38dd30c08ceeff81769f721a4a3a35a4.png");
                    background-size: cover;
                }

                .cart-style2 {
                    width: 22px;
                    height: 22px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/9585a77f1dd9d74ebf6ce47ccbb4983b.png");
                    background-size: cover;
                }

                .cart-style3 {
                    width: 38px;
                    height: 18px;
                    background-color: $primary-color;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #fff;
                }

            }
        }

        &.shadow {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }

        &.border {
            border: 1px solid #DEDEDE;
        }

    }

}

/*搜索*/
.item-search-cont {
    width: 100%;
    height: 57px;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    padding: 0px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .item-search {
        width: 100%;
        height: 33px;
        border-radius: 18px;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        padding-left: 43px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .search-icon {
            width: 17px;
            height: 17px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-29/a3eaf1e229da1a308ffb30abab11cad0.png");
            background-size: cover;
            position: absolute;
            left: 15px;
            top: 50%;
            margin-top: -9px;
        }

        .search-placeholder {
            font-size: 15px;
            line-height: 21px;
            color: #999;
        }

        .search-btn {
            width: 57px;
            height: 28px;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
            font-size: 15px;
            line-height: 21px;
            color: #fff;
            font-weight: bold;
            position: absolute;
            right: 3px;
            top: 50%;
            margin-top: -14px;
        }

        &.style1 {
            border: 1px solid #C2C2C2;
            background-color: #fff;
        }

        &.style2 {
            background-color: #F5F5F5;
            border: 0px;
        }


    }
}

/*扫码点餐*/
.tpl10-cont {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;

    .item-style1 {
        width: 100%;
        height: 122px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        border-radius: 10px;

        .scan-item {
            height: 100%;
            display: flex;
            flex: 1;
            flex-direction: column;
            position: relative;
            justify-content: center;
            align-items: center;

            .scan-item-img {
                width: 45px;
                height: 50px;
                background-size: cover;
                background-position: 50%;
                margin-bottom: 10px;
            }

            .txt {
                font-size: 14px;
                color: #000;
                line-height: 20px;
                font-weight: bold;
            }

            &::after {
                display: block;
                content: '';
                width: 1px;
                height: 79px;
                background-color: #F5F5F5;
                position: absolute;
                right: 0px;
                top: 50%;
                margin-top: -40px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }

    .item-style2 {
        width: 100%;
        height: 122px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .scan-item {
            width: 163px;
            height: 100%;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .scan-item-img {
                width: 45px;
                height: 50px;
                background-size: cover;
                background-position: 50%;
                margin-bottom: 10px;
            }

            .txt {
                font-size: 14px;
                color: #000;
                line-height: 20px;
                font-weight: bold;
            }
        }

    }
}

/*倒计时*/
.tpl11-cont {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;

    .count-down-style1 {
        width: 100%;
        height: 119px;
        background-size: cover;
        background-position: 50%;
        padding-top: 8px;
        border-radius: 8px;

        .title {
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .title-txt {
                font-size: 17px;
                line-height: 25px;
                position: relative;
                color: #FFD40D;
                font-weight: bold;
            }

            .stick {
                display: block;
                width: 25px;
                height: 2px;
                border-radius: 2px;
                margin-top: -2px;
                background-color: #FFD40D;

                &.before {
                    position: absolute;
                    left: -39px;
                    top: 50%;
                }

                &.after {
                    position: absolute;
                    right: -39px;
                    top: 50%;
                }
            }
        }

        .sub-title {
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #fff;
            line-height: 17px;
            margin-top: 3px;

        }

        .time-group {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            box-sizing: border-box;
            padding-top: 15px;
            padding-left: 10px;

            .time {
                width: 44px;
                height: 41px;
                background-color: #fff;
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                font-size: 23px;
                font-weight: bold;
                color: #A80000;
            }

            .unit {
                font-size: 12px;
                line-height: 16px;
                color: #fff;
                margin-left: 8px;
                margin-right: 8px;
            }

        }
    }
}

/*KTV包厢预订*/
.tpl12-cont {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;

    .booking-info {
        width: 100%;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        padding: 15px 0px;

        .book-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 0px 15px;

            .left {
                font-size: 17px;
                color: #000;
                font-weight: bold;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                line-height: 20px;

                .txt1 {
                    margin-right: 10px;
                }

                .book-store-name {
                    position: relative;
                    padding-left: 10px;

                    &::before {
                        display: block;
                        content: '';
                        width: 2px;
                        height: 15px;
                        background-color: rgba(0, 0, 0, 0.25);
                        position: absolute;
                        left: 0px;
                        top: 2px;
                    }
                }

            }

            .sales {
                font-size: 12px;
                color: #818181;
            }
        }

        .scroll-cont {
            width: 100%;
            margin-top: 12px;
            box-sizing: border-box;
            padding: 0px 15px;
        }

        .week-scroll {
            width: 100%;
            white-space: nowrap;
            overflow: auto;

            .week-scroll-cont {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .week {
                height: 44px;
                flex-shrink: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                margin-right: 32px;

                .txt1 {
                    font-size: 14px;
                    color: #000;
                    line-height: 20px;

                }

                .txt2 {
                    font-size: 13px;
                    color: #818181;
                    line-height: 19px;

                }

                &.active {
                    .txt1 {
                        color: $primary-color;
                    }

                    .txt2 {
                        color: $primary-color;
                    }
                }
            }

        }

        .room-scroll {
            width: 100%;
            white-space: nowrap;
            overflow: auto;
            margin-top: 7px;

            .room-scroll-cont {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .room {
                height: 48px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                flex-shrink: 0;
                background-color: #F4F5F6;
                border-radius: 4px;
                padding: 3px 5px;
                margin-right: 10px;
                line-height: 20px;
                collapse: #818181;

                .txt1 {
                    font-size: 14px;
                }

                .txt2 {
                    font-size: 13px;

                }

                &.active {
                    background: linear-gradient(116deg, #FF5A3D 0%, #FF345C 100%);
                    color: #fff;
                }
            }

        }

        .meal-scroll {
            width: 100%;
            white-space: nowrap;
            overflow: auto;
            margin-top: 7px;

            .meal-scroll-cont {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .meal {
                    height: 40px;
                    display: flex;
                    flex-shrink: 0;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    color: #818181;
                    border-radius: 4px;
                    background-color: #F4F5F6;
                    margin-right: 10px;
                    padding: 0px 17px;

                    &.active {
                        color: #fff;
                        background: linear-gradient(116deg, #FF5A3D 0%, #FF345C 100%);
                    }
                }
            }
        }


    }

    .time-cont {
        width: 100%;
        margin-top: 10px;

        .time-group-cont {}

        .time-group {
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #e5e5e5;
            padding: 0px 15px;
            padding-top: 14px;
            box-sizing: border-box;

            .title {
                font-size: 14px;
                line-height: 20px;
                color: #000;
                font-weight: bold;
            }

            .desc {
                width: 100%;
                display: flex;
                margin-top: 9px;
                justify-content: space-between;
                align-items: center;

                .price {
                    color: $primary-color;
                    font-weight: bold;
                    font-size: 14px;

                    span {
                        font-size: 20px;
                    }
                }

                .desc-arrow {
                    width: 10px;
                    height: 5px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-14/7b902d6f00fb2692be2c5575c20a4d09.png");
                    background-size: cover;

                    &.close {
                        transform: rotate(180deg);
                    }
                }

                .fi-text {
                    font-size: 13px;
                    color: #999;
                    margin-left: 5px;
                }
            }
        }

        .book-btn {
            width: 53px;
            height: 22px;
            background: linear-gradient(116deg, #FF5A3D 0%, #FF345C 100%);
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            color: #fff;

            &.disable {
                background: #999999;
                color: #fff;
            }
        }

        .meal-group {
            width: 100%;
            background-color: #f5f5f5;
            box-sizing: border-box;
            padding: 15px;

            .meal-title {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                color: #000;
                font-weight: bold;
            }

            .meal-item-cont {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 12px;

                .left {
                    width: 85px;
                    height: 85px;
                    flex-shrink: 0;
                    margin-right: 10px;
                    border-radius: 3px;
                    background-color: #d8d8d8;
                    background-size: cover;
                    background-position: 50%;

                }

                .right {
                    height: 85px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-direction: column;
                    box-sizing: border-box;
                    padding-bottom: 5px;

                    .txt1 {
                        font-size: 12px;
                        color: #000;
                        line-height: 17px;
                    }

                    .price {
                        color: $primary-color;
                        font-size: 12px;
                        font-weight: bold;

                        span {
                            font-size: 17px;
                        }
                    }
                }
            }

            &:last-child {
                padding-bottom: 30px;
            }
        }
    }

    .time-no-data {
        height: 250px;
    }
}

/*限时折扣*/
.tpl_13 {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;
}

.tpl13-cont {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;

    .title {
        display: flex;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            line-height: 25px;

            .count-down {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                color: #FF0000;
                font-size: 11px;
                margin-left: 5px;

                .count {
                    width: 17px;
                    height: 17px;
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 4px;
                    margin-right: 4px;
                    color: #fff;
                    background-color: #ff0000;
                }
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            color: #999;

            .arrow {
                width: 5px;
                height: 9px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-18/d373e9cb0ded2245d841ffe5ef18543e.png");
                background-size: cover;
                flex-shrink: 0;
                margin-left: 5px;
            }

        }
    }

    .item-list {
        width: 100%;
        height: 131px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;

        .left {
            width: 131px;
            height: 131px;
            border-radius: 4px;
            flex-shrink: 0;
            background-size: cover;
            background-position: 50% 50%;
            margin-right: 20px;
            background-color: #f5f5f5;
        }

        .right {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .txt1 {
                width: 175px;
                font-size: 14px;
                color: #000;
                line-height: 18px;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
            }

            .right-img-cont {
                width: 100%;
                height: 49px;
                position: relative;
                margin-top: 12px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-13/7da19125844f52db20b14563be87745d.png");
                background-size: 183px 22px;
                background-position: 50% 26px;
                background-repeat: no-repeat;

                .price {
                    font-size: 12px;
                    color: #EFBA98;
                    font-weight: bold;
                    position: absolute;
                    left: 0px;
                    top: 4px;

                }

                .arrow-cont {
                    width: 51px;
                    height: 39px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-13/25f659fc8d70f6303f1b46e8afe1733e.png");
                    background-size: cover;
                    position: absolute;
                    left: 50%;
                    margin-left: -28px;
                    top: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    font-size: 12px;
                    color: #733700;
                    line-height: 15px;
                }

                .fi-txt {
                    font-size: 12px;
                    color: #EFBA98;
                    font-weight: bold;
                    position: absolute;
                    right: 0px;
                    top: 4px;

                }

            }

            .right-line {
                width: 184px;
                height: 45px;
                margin-top: 5px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-17/f6b60d872dbc59150689a477fb8580f5.png");
                background-size: cover;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .line-left {
                    height: 100%;
                    text-align: left;
                    color: #fff;
                    box-sizing: border-box;
                    padding-left: 5px;

                    .left-txt1 {
                        font-size: 22px;
                        font-weight: bold;
                        line-height: 27px;
                    }

                    .left-txt2 {
                        font-size: 12px;

                    }
                }

                .line-right {
                    height: 100%;
                    text-align: right;
                    color: #EFBA98;
                    box-sizing: border-box;
                    padding-right: 12px;
                    padding-top: 3px;

                    .right-txt1 {
                        font-size: 15px;
                        font-weight: bold;
                        line-height: 22px;
                    }

                    .right-txt2 {
                        font-size: 12px;
                        line-height: 15px;

                    }
                }
            }

            .right-fi-txt {
                font-size: 12px;
                color: #999;
                line-height: 15px;
                margin-top: 3px;
            }
        }

        &.soon {
            .right {
                .right-line {
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-13/3ad4468be576a85ddf6e43aa92272385.png");

                    .line-right {
                        color: #733700;
                    }
                }
            }
        }
    }

}

.tpl13-style2 {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 0px;
    overflow-x: auto;

    .flex-cont {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    .item-list-style2 {
        width: 120px;
        flex-shrink: 0;
        border-radius: 3px;
        background-color: #fff;
        margin-right: 15px;
        margin-bottom: 0px;
        box-sizing: border-box;

        .top-img {
            width: 100%;
            height: 120px;
            border-radius: 3px 3px 0px 0px;
            background-color: #f5f5f5;
            background-size: cover;
            background-position: 50% 50%;

            &.noImg {
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/5b39b12285b4820bbd8940ed72fd7e79.png");
                background-size: 45px 39px;
                background-repeat: no-repeat;
            }

        }

        .item-desc {
            width: 100%;
            box-sizing: border-box;
            padding: 5px;

            .title {
                font-size: 14px;
                color: #000;
                line-height: 17px;
                height: 34px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-weight: bold;
            }

            .sub-title {
                font-size: 12px;
                color: #999;
                line-height: 16px;
                height: 16px;
                margin-top: 4px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .price-group {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 12px;

                .price {
                    color: $primary-color;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 26px;

                    span {
                        font-size: 16px;
                    }
                }

                .cart {
                    width: 22px;
                    height: 22px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-22/38dd30c08ceeff81769f721a4a3a35a4.png");
                    background-size: cover;
                }

                .cart-style3 {
                    width: 38px;
                    height: 18px;
                    background: linear-gradient(90deg, #FF0000 0%, #FF7B50 100%);
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #fff;

                    &.soon {
                        background: linear-gradient(90deg, #565CFF 0%, #54D0FF 100%);
                    }
                }

            }
        }

        &.shadow {
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        }

        &.border {
            border: 1px solid #DEDEDE;
        }

    }

}

/*场馆预订*/
.tpl_14 {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 10px;
}

.tpl14-cont {
    width: 100%;
    box-sizing: border-box;

    .title {
        font-size: 16px;
        line-height: 23px;
        color: #000;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .court-style1 {
        .court-list {
            width: 100%;
            height: 100px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            ;
            padding: 10px 12px;
            margin-bottom: 10px;

            .left {
                width: 135px;
                height: 76px;
                border-radius: 10px;
                flex-shrink: 0;
                background-size: cover;
                background-position: 50%;
                margin-right: 10px;
            }

            .right {
                width: calc(100% - 145px);

                .name {
                    font-size: 16px;
                    line-height: 23px;
                    color: #000;
                    font-weight: bold;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .tags-cont {
                    margin-top: 15px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 17px;

                    .tag {
                        height: 17px;
                        box-sizing: border-box;
                        border: 1px solid #999999;
                        border-radius: 2px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                        color: #999;
                        padding: 0px 7px;
                        margin-right: 5px;
                    }
                }

                .address {
                    width: 100%;
                    font-size: 12px;
                    color: #000;
                    line-height: 17px;
                    margin-top: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }



    }

    .court-style2 {
        .court-list {
            width: 100%;
            height: 275px;
            background: #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 10px;

            .store-img {
                width: 100%;
                height: 199px;
                background-size: cover;
                background-position: 50%;
                border-radius: 0px 0px 10px 10px;
            }

            .store-desc {
                width: 100%;
                box-sizing: border-box;
                padding: 12px 15px;

                .store-name {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left {
                        font-size: 16px;
                        line-height: 23px;
                        color: #000;

                    }

                    .book-btn {
                        width: 66px;
                        height: 27px;
                        background-color: #FF345C;
                        border-radius: 14px;
                        font-size: 14px;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;

                    }
                }

                .store-address {
                    font-size: 12px;
                    line-height: 17px;
                    color: #818181;
                    margin-top: 10px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

    }

    .court-style3 {
        .court-list {
            width: 100%;
            height: 199px;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            margin-bottom: 10px;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

            .store-img {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                left: 0px;
                top: 0px;
                background-size: cover;
                background-position: 50%;
            }

            .store-desc {
                width: 100%;
                height: 49px;
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                z-index: 2;
                left: 0px;
                bottom: 0px;
                box-sizing: border-box;
                padding: 5px 15px 0px 15px;

                .name {
                    font-size: 14px;
                    line-height: 20px;
                    color: #fff;
                    width: 255px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .address {
                    font-size: 11px;
                    line-height: 16px;
                    color: #fff;
                    width: 255px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .book-btn {
                    width: 66px;
                    height: 28px;
                    background-color: #FF345C;
                    border-radius: 14px;
                    font-size: 14px;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    position: absolute;
                    right: 15px;
                    top: 12px;
                }
            }

        }
    }
}

// 课程

.flowList.course {
    .flow-item {
        .item-desc {
            .sub-title {
                font-size: 12px;
                color: #999;
                line-height: 16px;
                height: 16px;
                margin-top: 4px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .enroll-wrap {
                margin-top: 10px;
                display: flex;
                font-size: 12px;
                height: 30px;
                line-height: 30px;
                justify-content: space-between;
                border-radius: 15px;
                padding-left: 5px;

                background: #F5F5F5;

                .enroll-btn {
                    width: 76px;
                    text-align: center;
                    height: 30px;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 15px;
                    background: linear-gradient(116deg, #FF5A3D 0%, #FF345C 100%);
                }
            }
        }
    }
}

.cx-item-style2.course {

    .right {

        .sub-title {
            font-size: 12px;
            color: #999;
            line-height: 16px;
            height: 16px;
            margin-top: 4px;
            width: 100%;

            overflow: hidden;
            text-overflow: ellipsis;
        }

        .price {
            color: $primary-color;
            font-size: 14px;
            font-weight: bold;

            span {
                font-size: 20px;
            }
        }

        .enroll-wrap {

            display: flex;
            font-size: 12px;
            height: 30px;
            line-height: 30px;
            justify-content: space-between;
            border-radius: 15px;
            padding-left: 5px;

            background: #F5F5F5;

            .enroll-btn {
                width: 76px;
                text-align: center;
                height: 30px;
                color: #fff;
                font-size: 14px;
                border-radius: 15px;
                background: linear-gradient(116deg, #FF5A3D 0%, #FF345C 100%);
            }
        }
    }
}

// 教师
.flowList.teacher {
    .flow-item {
        .item-desc {
            .title {
                font-size: 18px;
                line-height: 20px;
                color: #000;
                font-weight: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .sub-title {
                font-size: 14px;
                color: #333;
                line-height: 20px;
                height: 20px;
                margin-top: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .tag-wrap {
                height: 22px;
                margin-top: 10px;
                overflow: hidden;

                span {
                    font-size: 12px;
                }

                .tag {
                    padding: 0 5px;
                    font-size: 10px;
                    border-radius: 3px;
                    margin: 0 10px 10px 0;
                    display: inline-block;
                }
            }


        }
    }
}

.cx-item-style2.teacher {

    .right {
        .title {
            font-size: 18px;
            line-height: 20px;
            color: #000;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .sub-title {
            font-size: 14px;
            color: #333;
            line-height: 20px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .tag-wrap {
            height: 48px;
            margin-top: 10px;
            overflow: hidden;
            padding-left: 36px;
            position: relative;

            span {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 12px;
            }

            .tag {
                padding: 0 5px;
                font-size: 10px;
                border-radius: 3px;
                margin: 0 10px 5px 0;
                display: inline-block;
            }
        }


    }
}

// 拼团列表
.group-bugying {
    &.start {
        --activity-primary-color: var(--start-color);
        --activity-bg-color: var(--start-default-bgcolor);
    }

    &.not-start {
        --activity-primary-color: var(--not-start-color);
        --activity-bg-color: var(--not-start-default-bgcolor);
    }

    .start {
        --activity-primary-color: var(--start-color);
        --activity-bg-color: var(--start-default-bgcolor);
    }

    .not-start {
        --activity-primary-color: var(--not-start-color);
        --activity-bg-color: var(--not-start-default-bgcolor);
    }
}

.flowList.group-bugying {

    .sub-title {
        font-size: 12px;
        color: #999;
        line-height: 16px;
        height: 16px;
        margin-top: 4px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .item-img {
        position: relative;
        border-radius: 10px 10px 0px 0px;

        .time {
            width: 100%;
            height: 36px;
            padding: 0 6px;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--activity-bg-color);
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            line-height: 36px;
            color: var(--activity-text-color);
            opacity: 0.8;
        }
    }

    .group {
        display: inline-block;
        margin-right: 10px;
        border: 1px solid var(--activity-primary-color);
        border-radius: 3px;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        font-size: 12px;
        background: var(--activity-primary-color);

        .group-left {
            height: 20px;
            display: inline-block;
            width: 23px;
            color: #fff;
        }

        .group-right {
            height: 20px;
            display: inline-block;
            width: 33px;
            color: var(--activity-primary-color);
            background: #fff;
            border-radius: 0 3px 3px 0;
        }
    }

    .avatar-wrap {
        padding: 10px 0;
        display: flex;

        .avatar-list {
            display: flex;

            .img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                z-index: 4;

                &:nth-child(2) {
                    margin-left: -10px;
                    z-index: 3;
                }

                &:nth-child(3) {
                    margin-left: -10px;
                    z-index: 2;
                }
            }

            .img-more {
                width: 20px;
                height: 20px;
                background: #D5D5D5;
                border-radius: 50%;
                text-align: center;
                line-height: 13px;
                font-size: 15px;
                font-weight: bold;
                color: #fff;
                margin-left: -10px;
                position: relative;
                z-index: 1;
            }
        }

        .subtitle {
            font-size: 12px;
            margin-left: 10px;
            color: #999;
        }
    }

    .btn {
        display: flex;
        height: 26px;
        line-height: 26px;
        color: var(--activity-text-color);
        border-radius: 13px;
        overflow: hidden;

        .price {
            flex: 1;
            font-size: 11px;
            padding-left: 10px;
            background: var(--activity-bg-color);

            span {
                font-size: 16px;
            }
        }

        .btn-right {
            width: 50px;
            font-size: 14px;
            background: #000;
            position: relative;

            &::before {
                content: '';
                width: 0px;
                height: 0px;
                border-top: 13px solid transparent;
                border-bottom: 13px solid #000;
                border-left: 6px solid transparent;
                border-right: 6px solid #000;
                position: absolute;
                top: 0px;
                left: -11px;
            }
        }
    }

}

.cx-item-style2.group-bugying {

    .left {
        position: relative;

        .time {
            width: 100%;
            height: 27px;
            padding: 0 6px;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--activity-bg-color);
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            line-height: 27px;
            color: var(--activity-text-color);
            opacity: 0.8;
        }
    }

    .right {
        .group {
            display: inline-block;
            margin-right: 10px;
            border: 1px solid var(--activity-primary-color);
            border-radius: 3px;
            text-align: center;
            line-height: 20px;
            font-weight: 500;
            font-size: 12px;
            background: var(--activity-primary-color);

            .group-left {
                height: 20px;
                display: inline-block;
                width: 23px;
                color: #fff;
            }

            .group-right {
                height: 20px;
                display: inline-block;
                width: 33px;
                color: var(--activity-primary-color);
                background: #fff;
                border-radius: 0 3px 3px 0;
            }
        }

        .right-bottom {
            bottom: 0;
        }

        .sub-title {
            height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .avatar-wrap {
        padding-bottom: 10px;
        display: flex;

        .avatar-list {
            display: flex;

            .img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                z-index: 4;

                &:nth-child(2) {
                    margin-left: -10px;
                    z-index: 3;
                }

                &:nth-child(3) {
                    margin-left: -10px;
                    z-index: 2;
                }
            }

            .img-more {
                width: 20px;
                height: 20px;
                background: #D5D5D5;
                border-radius: 50%;
                text-align: center;
                line-height: 13px;
                font-size: 15px;
                font-weight: bold;
                color: #fff;
                margin-left: -10px;
                position: relative;
                z-index: 1;
            }
        }

        .subtitle {
            font-size: 12px;
            margin-left: 10px;
            color: #999;
        }
    }

    .btn {
        display: flex;
        height: 32px;
        line-height: 32px;
        color: var(--activity-text-color);
        border-radius: 16px;
        overflow: hidden;

        .price {
            flex: 1;
            font-size: 12px;
            padding-left: 10px;
            background: var(--activity-bg-color);

            span {
                font-size: 18px;
            }
        }

        .btn-right {
            width: 60px;
            font-size: 15px;
            background: #000;
            position: relative;

            &::before {
                content: '';
                width: 0px;
                height: 0px;
                border-top: 16px solid transparent;
                border-bottom: 16px solid #000;
                border-left: 8px solid transparent;
                border-right: 8px solid #000;
                position: absolute;
                top: 0px;
                left: -16px;
            }
        }
    }
}

.item-style4.group-bugying {
    .item-list {

        .top-img {
            position: relative;

            .time {
                width: 100%;
                height: 27px;
                padding: 0 6px;
                box-sizing: border-box;
                position: absolute;
                bottom: 0;
                left: 0;
                background: var(--activity-bg-color);
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                line-height: 27px;
                color: var(--activity-text-color);
                opacity: 0.8;
            }

        }

        .item-desc {
            .title {
                height: 36px;
            }

            .price-group {
                .price {
                    color: var(--activity-primary-color);
                }

                .btn {
                    height: 20px;
                    width: 55px;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 20px;
                    font-size: 12px;
                    font-weight: 600;
                    background: var(--activity-bg-color);
                    color: var(--activity-text-color);
                }
            }

        }


        .group {
            display: inline-block;
            margin-right: 10px;
            border: 1px solid var(--activity-primary-color);
            border-radius: 3px;
            text-align: center;
            line-height: 16px;
            font-weight: 500;
            font-size: 10px;
            background: var(--activity-primary-color);

            .group-left {
                height: 16px;
                display: inline-block;
                width: 20px;
                color: #fff;
            }

            .group-right {
                height: 16px;
                display: inline-block;
                width: 28px;
                color: var(--activity-primary-color);
                background: #fff;
                border-radius: 0 3px 3px 0;
            }
        }
    }
}

// 笔记列表

.note-item-style1 {
    width: 173px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 8px;

    .item-img {
        width: 173px;
        height: 173px;
        background-color: #f5f5f5;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 10px 10px 0px 0px;

        &.img-auto {
            height: auto;

            img {
                width: 100%;
                height: auto;
                border-radius: 10px 10px 0 0;
            }
        }
    }

    .item-desc {
        width: 100%;
        box-sizing: border-box;
        padding: 4px 7px 6px 7px;

        .title {
            font-size: 14px;
            line-height: 17px;
            font-weight: bold;
            color: #000;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .note-user {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .user-img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-size: cover;
                background-position: 50% 50%;
                flex-shrink: 0;
                margin-right: 7px;
                background-color: #ccc;
            }

            .user-name {
                flex: 1;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .like {
                flex-shrink: 0;
                font-size: 12px;
                display: flex;
                align-items: center;

                .icon-zan {
                    width: 14px;
                    height: 12px;
                    background: url('https://img.cxkoo.com/chengxuan/1/2022-10-19/0963198d675c97798bcd6963e01ab9bb.png');
                    background-size: cover;
                    background-position: 50% 50%;
                }

            }
        }


    }

}

.note-item-style2 {
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;

    &:last-child {
        margin: 0;
    }

    .note-item {
        display: flex;

        .right {
            width: 173px;
            height: 129px;
            background-color: #f5f5f5;
            background-size: cover;
            background-position: 50% 50%;
            border-radius: 10px;
            margin-left: 10px;
        }

        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                font-size: 14px;
                line-height: 17px;
                font-weight: bold;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .desc {
                .user {
                    display: flex;
                    margin-bottom: 14px;

                    .user-img {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-size: cover;
                        background-position: 50% 50%;
                        flex-shrink: 0;
                        margin-right: 7px;
                        background-color: #ccc;
                    }

                    .user-name {
                        flex: 1;
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .eye {
                    font-size: 12px;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;

                    .icon {

                        width: 17px;
                        height: 12px;
                        background-image: url('https://img.cxkoo.com/chengxuan/1/2022-10-11/5e42cffa0121750ce9b00a9853e88a5c.png');
                        background-size: cover;
                        background-position: 50% 50%;
                        margin-right: 5px;
                    }
                }

                .like {
                    font-size: 12px;
                    display: flex;
                    align-items: center;

                    .icon {
                        width: 14px;
                        height: 12px;
                        background-image: url('https://img.cxkoo.com/chengxuan/1/2022-10-19/0963198d675c97798bcd6963e01ab9bb.png');
                        background-size: cover;
                        background-position: 50% 50%;
                        margin-right: 5px;
                    }
                }
            }
        }


    }

    .item-wrap {
        height: 65px;
        width: 100%;
        margin-top: 20px;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .item {
            width: 265px;
            height: 65px;
            padding: 7px;
            box-sizing: border-box;
            background: #F5F5F5;
            border-radius: 10px;
            display: flex;


            .item-img {
                width: 50px;
                height: 50px;
                border-radius: 5px;
                background-size: cover;
                background-position: 50% 50%;
                flex-shrink: 0;
                margin-right: 10px;
            }

            .item-right {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 13px;
                    line-height: 17px;
                    color: #000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .price {
                    font-size: 12px;
                    font-weight: bold;
                    color: #f00;
                }
            }
        }
    }
}

// 优惠券
.d-plone-coupon {
    padding: 10px;
    border-radius: 5px;
    background: #fff;

    .coupon-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 6px;

        .tag {
            padding: 2px 12px;
            font-size: 12px;
            border-radius: 15px;
            background: var(--coupon-btn-text-color);
            color: #fff;
            margin-bottom: 5px;
        }

        .title {
            color: var(--coupon-btn-text-color);

            .txt2 {
                font-size: 20px;
            }

            .txt1 {
                font-size: 16px;
                position: relative;

                &::before {
                    content: '';
                    width: 7px;
                    height: 10px;
                    position: absolute;
                    top: 6px;
                    left: -25px;
                    background: url('https://img.cxkoo.com/chengxuan/1/2022-12-05/760e57f55e071d66c94c75697b036037.png');
                    background-size: contain;
                    background-position: 50% 50%;
                }

                &::after {
                    content: '';
                    width: 7px;
                    height: 10px;
                    position: absolute;
                    top: 6px;
                    right: -25px;
                    background: url('https://img.cxkoo.com/chengxuan/1/2022-12-05/d0b646c3b8906c77bba224b1a0f6d0ce.png');
                    background-size: contain;
                    background-position: 50% 50%;
                }
            }
        }
    }

    .coupon-style1 {
        display: flex;
        flex-wrap: wrap;

        .coupon-item {
            padding: 12px 0;
            margin-top: 14px;
            border-radius: 6px;
            width: calc((100% - 12px) / 3);
            height: 156px;
            text-align: center;
            background: var(--coupon-color);
            color: var(--coupon-text-color);
            box-sizing: border-box;
            margin-right: 6px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .btn {
                width: 75px;
                height: 24px;
                background: var(--coupon-btn-color);
                color: var(--coupon-btn-text-color);
                border-radius: 12px;
                line-height: 24px;
                margin: 15px auto 0;
            }
        }
    }

    .coupon-style2 {
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;

        .coupon-item {
            padding: 6px 0;
            margin-top: 14px;
            width: calc(100% / 2.5);
            height: 94px;
            text-align: center;
            border-radius: 3px;
            background: var(--coupon-color);
            color: var(--coupon-text-color);
            flex-shrink: 0;
            margin-right: 10px;
            position: relative;
            box-sizing: border-box;

            &::before {
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #fff;
                position: absolute;
                top: 50%;
                left: -8px;
            }

            &::after {
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #fff;
                position: absolute;
                top: 50%;
                right: -8px;
            }

            .tag {
                padding: 2px 7px;
                background: rgba(255, 255, 255, 0.8);
                color: var(--coupon-color);
                border-radius: 0px 3px 0px 3px;
                font-size: 12px;
                position: absolute;
                top: 0;
                right: 0;

            }

            .btn {
                width: 80px;
                height: 20px;
                background: var(--coupon-btn-color);
                color: var(--coupon-btn-text-color);
                line-height: 20px;
                margin: 5px auto 0;
            }
        }

    }

    .coupon-style3 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .coupon-item {
            padding: 12px;
            margin-top: 14px;
            border-radius: 6px;
            width: calc((100% - 10px) / 2);
            // height: 156px;
            text-align: center;
            background: var(--coupon-color);
            color: var(--coupon-text-color);
            box-sizing: border-box;

            .btn {
                width: 75px;
                height: 24px;
                background: var(--coupon-btn-color);
                color: var(--coupon-btn-text-color);
                line-height: 24px;
            }
        }
    }
}

// 卡片轮播图
.card-swiper {
    padding: 0 10px;

    .swiper-bg {
        width: 100%;
        height: 130px;
        border-radius: 10px;
        background-size: 100% 100%;
        background-position: 50% 50%;
    }

    .swiper {
        padding: 0 10px;
        display: flex;
        flex-wrap: nowrap;
        margin-top: -50px;
        overflow: hidden;

        .swiper-item {
            width: 160px;
            height: 100px;
            margin-right: 10px;
            flex-shrink: 0;
            border-radius: 5px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

// 3d轮播图
.swiper-3d {
    padding: 0 10px;

    .swiper-bg {
        width: 100%;
        height: 490px;
        border-radius: 10px;
        background-size: 100% 100%;
        background-position: 50% 50%;

        .swiper {
            height: 320px;
            padding-top: 120px;
            display: flex;
            flex-wrap: nowrap;
            box-sizing: content-box;
            overflow: hidden;

            .swiper-item {
                width: 236px;
                height: 320px;
                margin-right: 10px;
                flex-shrink: 0;
                border-radius: 5px;
                overflow: hidden;

                &:nth-child(1) {
                    transform: translate3d(-150px, 0px, -100px) rotateX(0deg) rotateY(50deg) scale(0.9);
                }

                &:nth-child(2) {
                    transform: translate3d(-195px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1);
                }

                &:nth-child(3) {
                    transform: translate3d(-240px, 0px, -100px) rotateX(0deg) rotateY(-51deg) scale(0.9);
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

// 社群团购
.group-solitaire {
    padding: 15px 10px 10px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;

    &.shadow {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    }

    &.border {
        border: 1px solid #DEDEDE;
        box-sizing: content-box;
    }

    .cover_imgs {
        display: flex;

        .img {
            width: 105px;
            height: 105px;
            border-radius: 5px;
            margin-left: 10px;
            background-size: contain;
            background-position: 50% 50%;
            background-color: #f5f5f5;

            &:first-child {
                margin: 0;
            }
        }
    }

    .tags {
        position: absolute;
        top: 0;
        left: 0;

        .tag {
            width: 46px;
            height: 46px;
            background: url('https://img.cxkoo.com/chengxuan/1/2023-01-12/dc7090b0ff86568699e4fab4b43bb56c.png');
            background-size: contain;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
            padding-top: 3px;
            box-sizing: border-box;

            &.no-start {
                background: url('https://img.cxkoo.com/chengxuan/1/2023-01-12/e849900d5c0575404d15374e37a4a6ff.png');
                background-size: contain;
                background-position: 50% 50%;
                background-repeat: no-repeat;
            }
        }
    }

    .title {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .price {
        margin-top: 8px;
        font-size: 13px;
        font-weight: 600;
        color: #FF0000;

        .bold {
            font-size: 20px;
        }
    }

    .related {
        border-top: 1px solid #F5F5F5;
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .left {
            display: flex;
            color: #484848;
            font-size: 12px;

            .eye-icon {
                margin-right: 10px;

                &::before {
                    content: '';
                    margin-right: 4px;
                    display: inline-block;
                    width: 16px;
                    height: 12px;
                    background-image: url('https://img.cxkoo.com/chengxuan/1/2023-01-10/2dcc57bc46b805f4c6309040f04abef7.png');
                    background-size: cover;
                    background-position: 50% 50%;
                    vertical-align: middle;
                }
            }

            .cart-icon {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 15px;
                    height: 12px;
                    background-image: url('https://img.cxkoo.com/chengxuan/1/2023-01-10/fad6daef8fa1b1179b12f76c715610a0.png');
                    background-size: cover;
                    background-position: 50% 50%;
                    vertical-align: middle;
                    margin-right: 4px;
                }
            }
        }

        .right {
            width: 80px;
            height: 30px;
            background: #FF3636;
            border-radius: 15px;
            color: #fff;
            font-size: 12px;
            text-align: center;
            line-height: 30px;
        }
    }
}

// 积分兑换
.point-exchange-tabs {
    height: 46px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    overflow: hidden;
    margin-bottom: 15px;

    .point-exchange-tab{
        height: 30px;
        line-height: 30px;
        padding: 0 20px;
        flex-shrink: 0;
        color: #484848;
        font-size: 15px;

        &.active{
            color: #000;
            font-size: 16px;
            font-weight: bold;
            position: relative;

            &::before{
                content: '';
                width: 40%;
                height: 4px;
                background: #f00;
                border-radius: 4px;
                position: absolute;
                left: 50%;
                bottom: -7px;
                transform: translateX(-50%);

            }

        }
    }
}