@import "./cx-diy-mixins.scss";
.diy-phone{
    position: relative;
    top: 30px;
    width: $designerWidth;
    height: $designerHeight;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #e7e7e7;
    overflow: hidden;
    box-sizing: content-box;
    .phone-contain{
        width: 100%;
        height: 100%;
        background-color: #fff;
        position: relative;
        border-top: 0px;
        border-bottom: 0px;
        z-index: 1;
        box-sizing: border-box;
    }
    .phone-title{
        width: 100%;
        height: 64px;
        background-image: url(https://img.cxkoo.com/chengxuan/3/2021-09-22/554d19752d39df2f36ae183b2a5216fa.png);
        background-size: cover;
        font-size: 14px;
        color: #000;
        text-align: center;
        box-sizing: border-box;
        padding-top: 30px;
        .phone-title-txt{
            font-size: 17px;
            font-weight: 600;
            color: #111;
        }

    }
    .phone-inner{
        width: 100%;
        box-sizing: border-box;
        height: 585px;
        overflow: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            width:1px;
            height:8px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-track{
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: #F5F5F5;
            border-radius: 10px;
            display: none;
        }
        &::-webkit-scrollbar-thumb{
            border-radius: 10px;
            background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1, rgba(0,0,0,.2)));
        }
        &.withoutNav{
            height:640px;
        }
    }
    .phone-nav{
        position: absolute;
        bottom:0px;
        left: 0px;
        width: 100%;
        height: 55px;
        display: flex;
        background-color: #fff;
        z-index: 9999;
        box-sizing: border-box;
        border: 0px;
        box-shadow: 0px -2px 10px 0px rgba(50,50,51,0.1);
        .navlist{
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-sizing: border-box;
            padding-top: 5px;
            img{
                width: 23px;
                height: 23px;
                object-fit: contain;
                margin-bottom: 3px;
            }
            p{
                font-size: 12px;;
            }
        }

        &.show-core{
            height: 70px;
			background-image: url('https://img.cxkoo.com/chengxuan/1/2022-11-03/69368aff786d9b8a6e83ef9bdc0b35ed.png');
			background-size: cover;
			background-position: 50% 50%;
			background-color: transparent !important;
			box-shadow:none;

            .navlist{
				padding-top: 20px;
			}
        }

        .nav-core{
			width: 65px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			img{
				width: 45px;
				height: auto;
				border-radius: 50%;
			}
		}
    }
}
