.left-panel-scroll{
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar{
        width:1px;
        height:8px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #F5F5F5;
        border-radius: 10px;
        display: none;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1, rgba(0,0,0,.2)));
    }
}
.radio-module{
    .title{
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        position: relative;
        box-sizing: border-box;
        &.required{
            padding-left: 12px;
            position: relative;
            &::before{
                display: block;
                content: '*';
                font-size: 14px;
                color: red;
                position: absolute;
                left: 0px;
                top: 0px;
                font-weight: normal;
            }

        }
    }
    .sub-title{
        font-size: 12px;
        line-height: 28px;
    }

    
    .radio{
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .radio-icon{
            width: 14px;
            height: 14px;
            display: inline-block;
            border: 1px solid #999;
            box-sizing: border-box;
            border-radius: 50%;
            margin: 0 10px 0 5px;
        }
        .radio-icon.active{
            background-color: #67C23A;
            border: 1px solid #67C23A;
            color: #fff;
            background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-02/de2ceea983ec05a571bc60eb8ef77852.png");
            background-size: 10px 10px ;
            background-repeat: no-repeat;
            background-position: 50%;
        }
        .radio-content{
            flex: 1;
            width:100%;
        }
    }
}

.check-box-module{
    .title{
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        position: relative;
        box-sizing: border-box;
        &.required{
            padding-left: 12px;
            position: relative;
            &::before{
                display: block;
                content: '*';
                font-size: 14px;
                color: red;
                position: absolute;
                left: 0px;
                top: 0px;
                font-weight: normal;
            }

        }
    }
    .sub-title{
        font-size: 12px;
        line-height: 28px;
    }
    .check-box{
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .check-box-icon{
            width: 14px;
            height: 14px;
            display: inline-block;
            border: 1px solid #999;
            box-sizing: border-box;
            margin: 0 10px 0 5px;
            &.active{
                background-color: #67C23A;
                border: 1px solid #67C23A;
                color: #fff;
                background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-02/de2ceea983ec05a571bc60eb8ef77852.png");
                background-size: 10px 10px ;
                background-repeat: no-repeat;
                background-position: 50%;
            }
        }
        .check-box-content{
            flex: 1;
            width:100%;
        }
    }
}

.img-radio-module{
    .title{
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        position: relative;
        box-sizing: border-box;
        &.required{
            padding-left: 12px;
            position: relative;
            &::before{
                display: block;
                content: '*';
                font-size: 14px;
                color: red;
                position: absolute;
                left: 0px;
                top: 0px;
                font-weight: normal;
            }

        }
    }
    .sub-title{
        font-size: 12px;
        line-height: 28px;
    }
   
    .img-cont{
        width: 100%;
    }
    .radio{
        flex: 1;
        padding: 10px 10px 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .radio-icon{
            width: 14px;
            height: 14px;
            display: inline-block;
            border: 1px solid #999;
            box-sizing: border-box;
            border-radius: 50%;
            margin: 0 10px 0 5px;
            flex-shrink: 0;
        }
        .radio-icon.active{
            background-color: #67C23A;
            border: 1px solid #67C23A;
            color: #fff;
            background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-02/de2ceea983ec05a571bc60eb8ef77852.png");
            background-size: 10px 10px ;
            background-repeat: no-repeat;
            background-position: 50%;
        }
        .radio-content{
            flex: 1;
            
        }
    }

    .img-radio{
        border: 1px solid #eee;
        margin-top: 20px;
        border-radius: 3px;
        overflow: hidden;
        &.style2{
            display: flex;
            .img-cont{
                width: 150px;
                min-height: 150px;
                flex-shrink: 0;
                background: #f5f5f5;
                display: flex;
                align-items: center;
            }
        }
    }

}

.img-check-box-module{
    .title{
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        position: relative;
        box-sizing: border-box;
        &.required{
            padding-left: 12px;
            position: relative;
            &::before{
                display: block;
                content: '*';
                font-size: 14px;
                color: red;
                position: absolute;
                left: 0px;
                top: 0px;
                font-weight: normal;
            }
        }
    }
    .sub-title{
        font-size: 12px;
        line-height: 28px;
    }
    .img-cont{
        width: 100%;
    }
    .check-box{
        flex: 1;
        padding: 10px 10px 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .check-box-icon{
            width: 14px;
            height: 14px;
            display: inline-block;
            border: 1px solid #999;
            box-sizing: border-box;
            margin: 0 10px 0 5px;
            flex-shrink: 0;
        }
        .check-box-icon.active{
            background-color: #67C23A;
            border: 1px solid #67C23A;
            color: #fff;
            background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-02/de2ceea983ec05a571bc60eb8ef77852.png");
            background-size: 10px 10px ;
            background-repeat: no-repeat;
            background-position: 50%;
        }
        .check-box-content{
            flex: 1;
        }
    }
    .img-check-box{
        border: 1px solid #eee;
        margin-top: 20px;
        border-radius: 3px;
        overflow: hidden;
        &.style2{
            display: flex;
            .img-cont{
                width: 150px;
                min-height: 150px;
                flex-shrink: 0;
                background: #f5f5f5;
                display: flex;
                align-items: center;
            }
        }
    }
}

.select-module, .input-module{
    .title{
        font-size: 14px;
        font-weight: bold;
        line-height: 28px;
        position: relative;
        box-sizing: border-box;
        &.required{
            padding-left: 12px;
            position: relative;
            &::before{
                display: block;
                content: '*';
                font-size: 14px;
                color: red;
                position: absolute;
                left: 0px;
                top: 0px;
                font-weight: normal;
            }

        }
    }
    .sub-title{
        font-size: 12px;
        line-height: 28px;
    }
}

.multiple-cont{
    .multiple-item{
        margin-bottom: 10px;
        .item-title{
            line-height: 28px;
        }
        :last-child{
            margin-bottom: 0px;
        }
    }
}

.form-question-cont{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .form-question-item{
        width: 50%;
        box-sizing: border-box;
        padding-right: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        margin-top: 10px;
        .item-title{
            width: 50px;
            flex-shrink: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-right: 5px;
            text-align: right;
            line-height: 25px;
        }
        .item-input{
            height: 25px;
            display: flex;
            flex: 1;
            border-bottom: 1px solid #333;
        }
    }
}

.region-cont{
    .province-city{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .region{
            display: flex;
            flex: 1;
            margin-right: 10px;
            &:last-child{
                margin-right: 0px;
            }
            .ant-select-selection--single{
                width: 100%;
            }
        }
    }
    .address-cont{
        margin-top: 10px;
    }
}

.submit-button-cont{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    .submit-button{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ff0000;
        color: #fff;
        font-size: 14px;
        border-radius: 25px;
    }
}
