.diy-user-phone{
    width: 375px;
    min-height: 615px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #f5f5f5;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    .phone-title{
        width: 100%;
        height: 65px;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-02-28/ea28d84e317b8041c3e722fd76e68dfa.png");
        background-size: cover;
        position: relative;
        z-index: 1;
        color: #fff;
        .title-txt{
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 20px;
            padding-top: 15px;
        }
    }
    .phone-angle{
        width: 100%;
        height: 65px;
        background-color: #000;
        top: 0px;
        left: 0px;
        position: absolute;
    }
    .phone-inner{
        width: 100%;
        height: calc(100% - 65px);
        background-color: #f5f5f5;
        box-sizing: border-box;
        padding-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;

    }
    .phone-inner-poster{
        width: 100%;
        box-sizing: border-box;
        position: relative;
        .poster-img{
            width: 100%;
        }
        .qr-code{
            width: 90px;
            height: 90px;
            position: absolute;
            //left: 142px;
            //bottom: 50px;
            left: 0px;
            top: 0px;
            background-size: cover;
            background-position: 50%;
            cursor: move;
            &.radius{
                border-radius: 50%;
            }
        }
        .qr-code-txt{
            font-size: 12px;
            color: #fff;
            position: absolute;
            cursor: move;
            //left: 144px;
            //bottom: 30px;
            left: 0px;
            top: 0px;
            width: 300px;

        }
        .user-info{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            left: 0px;
            top: 0px;
            cursor: move;
            .avatar{
                width: 45px;
                height: 45px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-18/9cfa2e3c56dad53ea16fb6ade45c2fbb.png");
                background-size: cover;
                background-position: 50%;
                flex-shrink: 0;
                margin-right: 10px;
                &.radius{
                    border-radius: 50%;
                }
            }
            .nickname{
                font-size: 15px;
                color: #fff;
                font-weight: bold;
                flex-shrink: 0;

            }
        }
    }
    &.poster{
        min-height: 400px;
        overflow: visible;
    }
    &.recruit{
        min-height: 615px;
    }

}
.diy-user-ctrl{
    width: calc(100% - 400px)
}
.user-style1{
    .mine-cont{
        width: 100%;
        box-sizing: border-box;
    }
    .mine-head{
        width: 375px;
        height: 240px;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/4a7ba5fa6ca563d77bc2914de0883b6a.png");
        background-size: cover;
        background-position: 50%;
        position: relative;
    }
    .head-cont{
        width: 100%;
        box-sizing: border-box;
        padding: 0px 9px 0px 9px;
        position: absolute;
        left: 0px;
        bottom: -24px;
        .user-cont{
            width: 100%;
            height: 125px;
            background-color: #fff;
            border-radius: 10px;
            position: relative;
            text-align: center;
            box-sizing: border-box;
            padding-top: 38px;
            .avatar{
                width: 74px;
                height: 74px;
                border-radius: 50%;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-18/9cfa2e3c56dad53ea16fb6ade45c2fbb.png");
                background-color: #f5f5f5;
                background-size: cover;
                background-position: 50%;
                position: absolute;
                left: 50%;
                margin-left: -37px;
                top: -46px;
            }
            .icon-group{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: absolute;
                right: 11px;
                top: -32px;
                .icon-setting{
                    width: 20px;
                    height: 19px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/aa5eab481df30c7acbe798800aa3ab9a.png");
                    background-size: cover;
                }
                .icon-message{
                    width: 16px;
                    height: 19px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/58fbec8d5e42b0cc00e130c5a206cb11.png");
                    background-size: cover;
                }

            }
            .user-info{
                width: 100%;
                text-align: center;
                .nickname{
                    font-size: 20px;
                    line-height: 28px;
                    color: #000;
                    font-weight: bold;
                }
                .user-items{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    line-height: 19px;
                    color: #818181;
                    margin-top: 18px;
                    .user-item{
                        position: relative;
                        padding-left: 15px;
                        padding-right: 15px;
                        &::after{
                            display: block;
                            content: '';
                            width: 1px;
                            height: 13px;
                            position: absolute;
                            right: 0px;
                            top:3px;
                            background-color: #818181;
                            opacity: 0.7;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                    }
                }

            }
        }

    }
    .mine-items-cont{
        width: 100%;
        box-sizing: border-box;
        padding: 35px 9px 0px 9px;
    }
    .vip-close{
        width: 100%;
        height: 74px;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-10/58fe6577adc3fbc85e60296b74368b75.png");
        background-size: cover;
        position: relative;
        .txt1{
            font-size: 11px;
            color: #FDEAB7;
            position: absolute;
            left: 15px;
            top: 36px;
        }
        .vip-btn{
            width: 74px;
            height: 28px;
            background-color: #FDEAB7;
            border-radius:14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #000;
            position: absolute;
            right: 25px;
            top: 16px;
        }
    }
    .cx-mine-panel{
        width: 100%;
        box-sizing: border-box;
        padding: 0px 18px;
        background-color: #fff;
        border-radius: 11px;
        position: relative;
        z-index: 1;
        &.first{
            margin-top: -15px;
        }
    }
    .cx-panel-title{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #f5f5f5;
        font-size: 14px;
        color: #000;
        .left{
            font-weight: bold;
        }
        .right{
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            color: #999;
            .arrow{
                width: 5px;
                height: 9px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-19/046b023609aa5eca24b2bd01f64199af.png");
                background-size: cover;
                margin-left: 10px;
            }
        }
    }
    .order-item{
        height: 95px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 13px;
        color: #000;
        line-height: 18px;
        .item-img{
            background-size: cover;
            background-position: 50% 50%;
            position: relative;
            width: 23px;
            height: 23px;
            &.icon1{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/8e3d230085c1b3016361b656adc00986.png");
            }
            &.icon2{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/cb131567a630ab88ea346a65cd79ede9.png");
            }
            &.icon3{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/3b6723ee87c38c6be00685435d6a118d.png");
            }
            &.icon4{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/ad850186067ea1ff18b3547efea568b2.png");
            }
            &.icon5{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-09/3e4d47b49e6a34b93288434f8f0e0a3e.png");
            }
        }
        .txt{
            margin-top: 8px;
        }
        .badge{
            height: 16px;
            background-color: #ff0000;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            min-width: 16px;
            border-radius: 50%;
            font-size: 11px;
            color: #fff;
            padding: 0px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
        }
    }
    .cx-quick-items{
        .quick-item{
            width: 100%;
            height: 49px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            border-bottom: 1px solid #f5f5f5;
            .left{
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 13px;
                color: #000;
                .icon{
                    width: 21px;
                    height: 21px;
                    background-size: cover;
                    background-position: 50%;
                    margin-right: 15px;
                }
            }
            .right{
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                box-sizing: border-box;
                padding-right: 4px;
                .arrow{
                    width: 5px;
                    height: 9px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-19/046b023609aa5eca24b2bd01f64199af.png");
                    background-size: cover;
                    margin-left: 10px;
                }
            }
            &:last-child{
                border-bottom: 0px;
            }
        }
    }
}
.user-style2{
    .status-bar{
        height: 25px;
        width: 100%;
    }
    .mine-cont{
        width: 100%;
        box-sizing: border-box;
    }
    .mine-head{
        width: 100%;
        height: 275px;
        box-sizing: border-box;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/01ec0cd4af17f4cc63a4c0b4e98d99a2.png");
        background-size: cover;
        position: relative;

        &.hide-card{
            height: 200px;
        }
        .head-cont{
            position: relative;
            box-sizing: border-box;
            width: 100%;
            padding-top: 50px;
        }
        .user-cont{
            width: 100%;
            box-sizing: border-box;
            padding-left: 12px;
            .right{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                .sign{
                    width: 78px;
                    height: 33px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 16px 0px 0px 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    color: #000;
                    img{
                        width: 25px;
                        height: 25px;
                        margin-right: 4px;
                    }
                }
                .icon-group{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    right: 0px;
                    top: -30px;
                    .icon-setting{
                        position: relative;
                        width: 22px;
                        height: 22px;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/76f50b966810f232484919a06489c7ec.png");
                        background-size: cover;
                        background-position: 50% 50%;
                        margin-right: 15px;
                    }
                    .icon-message{
                        position: relative;
                        width: 22px;
                        height: 22px;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/035dea2ddec745d7c9a5d33a74e7319a.png");
                        background-size: cover;
                        background-position: 50% 50%;
                        margin-right: 15px;
                    }
                }
            }
        }
        .user-info{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .avatar{
                width: 74px;
                height: 74px;
                flex-shrink: 0;
                border-radius: 50%;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-18/9cfa2e3c56dad53ea16fb6ade45c2fbb.png");
                background-size: cover;
                background-position: 50% 50%;
                margin-right: 15px;
                background-color: #d8d8d8;
            }
            .no-auth{
                padding: 0px;
                background-color: transparent;
                font-size: 20px;
                color: #000;
                line-height: 28px;
            }
            .nick-name{
                font-size: 20px;
                color: #000;
                line-height: 28px;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .level{
                display: inline-block;
                text-align: center;
                line-height: 18px;
                height: 20px;
                border: 1px solid #999;
                font-size: 13px;
                color: #999;
                border-radius: 11px;
                padding: 0px 7px;
                margin-top: 4px;

            }
        }
        .member-card{
            width: 358px;
            height: 60px;
            margin: 0px auto;
            margin-top: 15px;
            box-sizing: border-box;
            background-color: #FFFBF5;
            border: 2px solid #E7CA92;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 12px;
            .left{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 15px;
                color: #000;
                img{
                    width: 22px;
                    height: 22px;
                    margin-right: 4px;
                    flex-shrink: 0;
                }
            }
            .right-btn{
                width: 77px;
                height: 24px;
                border-radius: 12px;
                border: 1px solid #783B10;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #733306;
            }
        }
    }
    .mine-content{
        width: 100%;
        box-sizing: border-box;
        padding: 0px 9px;
        position: relative;
        z-index: 2;
    }
    .cx-mine-panel{
        width: 100%;
        box-sizing: border-box;
        padding: 0px 18px;
        background-color: #fff;
        border-radius: 11px;
        &.move-top{
            margin-top: -38px;
        }
    }
    .cx-panel-title{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #f5f5f5;
        font-size: 14px;
        color: #000;
        .left{
            font-weight: bold;
        }
        .right{
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            color: #999;
            .arrow{
                width: 5px;
                height: 9px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-19/046b023609aa5eca24b2bd01f64199af.png");
                background-size: cover;
                margin-left: 10px;
            }
        }
    }
    .top-item{
        display: flex;
        flex: 1;
        height: 88px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .txt1{
            font-size: 18px;
            color: #000;
            line-height: 24px;
        }

        .txt2{
            font-size: 14px;
            color: #999;
            line-height: 19px;
            margin-top:4px;
        }
    }
    .order-item{
        height: 95px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 13px;
        color: #000;
        line-height: 18px;
        .item-img{
            background-size: cover;
            background-position: 50% 50%;
            position: relative;
            width: 22px;
            height: 22px;
            &.icon1{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/11f149e2e386d6ce389244247fd61eba.png");
            }
            &.icon2{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/11f149e2e386d6ce389244247fd61eba.png");
            }
            &.icon3{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/11757f4e5c88581781df4de228558996.png");
            }
            &.icon4{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/4d28032bf3823707b33300accc675be1.png");
            }
            &.icon5{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/964ae55cea471c2c87c5e62796c414b9.png");
            }
        }
        .txt{
            margin-top: 8px;
        }
        .badge{
            height: 16px;
            background-color: #ff0000;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            min-width: 16px;
            border-radius: 50%;
            font-size: 11px;
            color: #fff;
            padding: 0px 4px;
            position: absolute;
            right: -8px;
            top: -8px;
        }
    }
    .tool-item{
        width: 20%;
        height: 86px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        line-height: 18px;
        color: #000;
        padding: 0px;
        margin: 0px;
        background: transparent;
        img{
            width: 25px;
            height: 25px;
        }
        .txt{
            margin-top: 11px;
        }
    }
    .copy-right{
        width: 100%;
        box-sizing: border-box;
        padding: 20px 0;
        font-size: 13px;
        color: #999;
        text-align: center;
    }

}

.user-style3{
    .status-bar{
        height: 25px;
        width: 100%;
    }
    .mine-cont{
        width: 100%;
        box-sizing: border-box;
    }

    .top-item{
        display: flex;
        flex: 1;
        height: 88px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .txt1{
            font-size: 18px;
            color: #000;
            line-height: 24px;
        }

        .txt2{
            font-size: 14px;
            color: #999;
            line-height: 19px;
            margin-top:4px;
        }
    }

    .mine-head{
        width: 100%;
        height: 275px;
        box-sizing: border-box;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-11-02/527e594a1a5862bb2ad8ba1350b6f092.png");
        background-size: cover;
        position: relative;

        &.hide-card{
            height: 200px;
        }
        .head-cont{
            position: relative;
            box-sizing: border-box;
            width: 100%;
            padding-top: 50px;
        }
        .user-cont{
            width: 100%;
            box-sizing: border-box;
            padding-left: 12px;
            .right{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                .sign{
                    width: 78px;
                    height: 33px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 16px 0px 0px 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                    color: #000;
                    img{
                        width: 25px;
                        height: 25px;
                        margin-right: 4px;
                    }
                }
                .icon-group{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    right: 0px;
                    top: -30px;
                    .icon-setting{
                        position: relative;
                        width: 22px;
                        height: 22px;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/76f50b966810f232484919a06489c7ec.png");
                        background-size: cover;
                        background-position: 50% 50%;
                        margin-right: 15px;
                    }
                    .icon-message{
                        position: relative;
                        width: 22px;
                        height: 22px;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-20/035dea2ddec745d7c9a5d33a74e7319a.png");
                        background-size: cover;
                        background-position: 50% 50%;
                        margin-right: 15px;
                    }
                }
            }
        }
        .user-info{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .avatar{
                width: 74px;
                height: 74px;
                flex-shrink: 0;
                border-radius: 50%;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-18/9cfa2e3c56dad53ea16fb6ade45c2fbb.png");
                background-size: cover;
                background-position: 50% 50%;
                margin-right: 15px;
                background-color: #d8d8d8;
            }
            .no-auth{
                padding: 0px;
                background-color: transparent;
                font-size: 20px;
                color: #000;
                line-height: 28px;
            }
            .nick-name{
                font-size: 20px;
                color: #000;
                line-height: 28px;
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .level{
                display: inline-block;
                text-align: center;
                line-height: 18px;
                height: 20px;
                border: 1px solid #999;
                font-size: 13px;
                color: #999;
                border-radius: 11px;
                padding: 0px 7px;
                margin-top: 4px;

            }
        }
        .top-item{
            height: auto;
        }
    }

    .mine-content{
        width: 100%;
        box-sizing: border-box;
        padding: 0px 9px;
        position: relative;
        z-index: 2;
    }
    .cx-mine-panel{
        width: 100%;
        box-sizing: border-box;
        padding: 0px 18px;
        background-color: #fff;
        border-radius: 11px;
        &.move-top{
            margin-top: -50px;
        }
    }

    .member-card{
        width: 355px;
        height: 66px;
        box-sizing: border-box;
        padding: 16px 15px;
        margin: -50px auto 0px;
        background: url('https://img.cxkoo.com/chengxuan/1/2022-11-02/eb6dca269886d47cc41728fe98fa1231.png');
        background-size: 100% 100%;
      
        .txt1{
            width: 70px;
            height: 17px;
            background: url('https://img.cxkoo.com/chengxuan/1/2022-11-02/cc66e60579739805acdfe64334cd8270.png');
            background-size: 100% 100%;
        }
        .txt2{
            font-size: 11px;
            color: #fff;
            line-height: 15px;
            margin-top: 5px;
           
            &::after{
                display: inline-block;
                content: '';
                width: 5px;
                height: 9px;
                background: url('https://img.cxkoo.com/chengxuan/1/2022-11-02/50d528f761d3a168c636d1b984d0cc11.png');
                background-size: 100% 100%;
            }
        }

    }

    .cx-panel-title{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #f5f5f5;
        font-size: 14px;
        color: #000;
        .left{
            font-weight: bold;
        }
        .right{
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            color: #999;
            .arrow{
                width: 5px;
                height: 9px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-19/046b023609aa5eca24b2bd01f64199af.png");
                background-size: cover;
                margin-left: 10px;
            }
        }
    }

    .tool-item{
        width: 25%;
        height: 86px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        line-height: 18px;
        color: #000;
        padding: 0px;
        margin: 0px;
        background: transparent;
        img{
            // width: 30px;
            height: 30px;
        }
        .txt{
            margin-top: 11px;
        }
    }

    .cx-quick-items{
        .quick-item{
            width: 100%;
            height: 49px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            border-bottom: 1px solid #f5f5f5;
            .left{
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 13px;
                color: #000;
                .icon{
                    width: 21px;
                    height: 21px;
                    background-size: cover;
                    background-position: 50%;
                    margin-right: 15px;
                }
            }
            .right{
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                box-sizing: border-box;
                padding-right: 4px;
                .arrow{
                    width: 5px;
                    height: 9px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-19/046b023609aa5eca24b2bd01f64199af.png");
                    background-size: cover;
                    margin-left: 10px;
                }
            }
            &:last-child{
                border-bottom: 0px;
            }
        }
    }
   
}