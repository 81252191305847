.albums-icon-tab{
    width: 100%;
    box-sizing: border-box;
    padding: 0px 30px;
    height: 300px;
    overflow: auto;
}
.icon-color-line{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0;
}
.icon-color{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 51px;
    border: 1px solid #e5e5e5;
    padding: 5px;
    cursor: pointer;
    img{
        width: 100%;
        //height: 100%;
    }
    &.active{
        &::before{
            width: 25px;
            height: 25px;
            display: block;
            content: '';
            position: absolute;
            z-index: 3;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-13/b70074e461e8d9bb41c304479a8d886f.png");
            background-size: cover;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

        }
        &::after{
            width: 100%;
            height: 100%;
            display: block;
            content: '';
            background-color: rgba(0,0,0,0.3);
            position: absolute;
            z-index: 2;
            left: 0px;
            top: 0px;
        }
    }
}
