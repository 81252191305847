.set-color {
    width: 280px;
    height: 492px;
    border: 1px solid #eee;
    flex-shrink: 0;

    .title {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: #333;
        font-weight: bold;
        background-color: #f5f5f5;

        span {
            font-weight: 400;
            color: #999;
            font-size: 12px;
        }
    }

    .color-container {
        padding: 10px 10px 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .color-wrap {
            display: flex;
            border-radius: 3px;
            overflow: hidden;
            border: 1px solid #999;
            cursor: pointer;
            margin-top: 20px;

            &.active{
                box-shadow: 0px 0px 0px 2px #5E4CFF;
            }

            .color-item {
                width: 30px;
                height: 30px;
                background: #fff;
            }
        }
    }

    .diy-color {
        padding: 0 20px 20px;
    }
}

.example-phone {
    margin-left: 30px;
    flex: 1;
    height: 492px;
    display: flex;
    flex-shrink: 0;
   
    .phone-wrap {
        width: 222px;
        height: 490px;
        margin-right: 15px;
        position: relative;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
        flex-shrink: 0;

        .search-btn {
            width: 57px;
            height: 28px;
            background: var(--primary-color);
            border-radius: 18px;
            color: #fff;
            font-size: 15px;
            text-align: center;
            line-height: 28px;
            transform: scale(0.592);
            position: absolute;
            left: 145.5px;
            top: 40px;
        }

        .btn-group{
            width: 220px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            display: flex;
            border-radius: 20px;
            overflow: hidden;
            transform: scale(0.592);
            position: absolute;
            bottom: -2px;
            left: 41px;

            .btn{
                flex: 1;
                background: var(--primary-color);

                &.secondary{
                    background: var(--secondary-color);
                }
            }
        }

        &.phone1 {
            background: url('https://img.cxkoo.com/chengxuan/1/2022-09-15/f2509c21f55fecff18ab4dd081e31882.png') 50% no-repeat;
            background-size: 222px;

            .arc{
                color: var(--primary-color);
                position: absolute;
                top: 89px;
                left: 27.5px;
            }

            .price {
                width: 90px;
                height: 25px;
                color: var(--primary-color);
                font-size: 13px;
                font-weight: bold;
                transform: scale(0.592);
                position: absolute;
                left: 79px;

                .price-num {
                    font-size: 18px;
                }

                &.price1 {
                    top: 158.5px;
                }

                &.price2 {
                    top: 248.5px;
                }

                &.price3 {
                    top: 338.5px;
                }

                &.price4 {
                    top: 430.5px;
                }
            }

            .coupon {
                width: 100px;
                height: 20px;
                font-size: 12px;
                border: 1px solid var(--primary-color);
                display: flex;
                text-align: center;
                color: var(--primary-color);
                transform: scale(0.5);
                position: absolute;
                left: 72px;

                &.coupon1{
                    top: 265px;
                }

                &.coupon2{
                    top: 356px;
                }

                &.coupon3{
                    width: 71px;
                    top: 356px;
                    left: 134px;
                }

                .tag {
                    width: 30px;
                    height: 20px;
                    position: relative;
                    border-right: 1px dotted var(--primary-color);

                    .tag-bg{
                        width: 30px;
                        height: 20px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: var(--primary-color);
                        opacity: 0.1;
                    }
                }
            }
        }

        &.phone2 {
            background: url('https://img.cxkoo.com/chengxuan/1/2022-09-15/4e00e68f1606523d27fef12755ef70bf.png') 50% no-repeat;
            background-size: 222px;

            .goods{
                width: 60px;
                font-size: 15px;
                font-weight: bold;
                color: var(--primary-color);
                text-align: center;
                transform: scale(0.592);
                line-height: 1;
                position: absolute;
                left: 10px;
                top: 42.5px;
            }

            .collect{
                position: absolute;
                left: 168px;
                top: 46.5px;
                color: var(--primary-color);
                transform: scale(0.75);
            }

            .price{
                width: 120px;
                height: 32px;
                color: var(--primary-color);
                font-size: 13px;
                font-weight: bold;
                transform: scale(0.592);
                position: absolute;
                left: -9px;
                top: 265px;

                .price-num {
                    font-size: 23px;
                }
            }

            .cart-tag{
                width: 20px;
                height: 20px;
                background: var(--primary-color);
                font-size: 14px;
                color: #fff;
                text-align: center;
                line-height: 20px;
                border-radius: 50%;
                transform: scale(0.4);
                position: absolute;
                left: 64px;
                bottom: 15px;
            }
        
        }

        &.phone3 {
            background: url('https://img.cxkoo.com/chengxuan/1/2022-09-15/3bf6b208247faaeb518b5a99a3aa549d.png') 50% no-repeat;
            background-size: 222px;

            .price{
                width: 120px;
                height: 32px;
                color: var(--primary-color);
                font-size: 12px;
                font-weight: bold;
                transform: scale(0.592);
                position: absolute;
                left: 51px;
                top: 255px;

                .price-num {
                    font-size: 21px;
                }
            }

            .sku-tag{
                height: 30px;
                line-height: 30px;
                border: 1px solid var(--primary-color);
                position: relative;
                color: var(--primary-color);
                position: absolute;
                border-radius: 3px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: scale(0.592);

                &.sku1{
                    width: 72px;
                    top: 321px;
                    left: -4px;
                }

                &.sku2{
                    width: 32px;
                    top: 370px;
                    left: 4px;
                }

                .img{
                    width: 24px;
                    height: 24px;
                    background: url('https://img.cxkoo.com/chengxuan/1/2022-09-15/19622c30078be31a09dc5a25bf16a5d8.png');
                    background-size: 100% 100%;
                    margin-right: 8px;
                }

                .tag-bg{
                    width: 100%;
                    height: 30px;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    background: var(--primary-color);
                    opacity: 0.1;
                }
            }

            .text-desc{
                font-size: 14px;
                color: var(--primary-color);
                transform: scale(0.592);
                position: absolute;
                bottom: 27px;
                left: 88px;
            }

            .btn-group{
                width: 350px;
                bottom: -3px;
                left: -64px;
            }
        }

        &.phone4 {
            background: url('https://img.cxkoo.com/chengxuan/1/2022-09-15/78193ab4afa372e1160b9593fc118f95.png') 50% no-repeat;
            background-size: 222px;

            .search-btn {
                left: 124.5px;
                top: 40.5px;
            }

            .arc{
                color: var(--primary-color);
                position: absolute;
                top: 88px;
                left: 11.5px;
                font-size: 13px;
            }

            .text-desc{
                font-size: 14px;
                color: var(--primary-color);
                transform: scale(0.592);
                position: absolute;
                right: 0px;

                &.desc1{
                    top: 113px;
                }
                &.desc2{
                    top: 258px;
                }
                &.desc3{
                    top: 407px;
                }
            }

            .order-btn{
                width: 83px;
                height: 34px;
                border: 1px solid var(--primary-color);
                color: var(--primary-color);
                border-radius: 17px;
                line-height: 32px;
                font-size: 14px;
                text-align: center;
                transform: scale(0.592);
                position: absolute;
                right: -6px;
                
                &.btn1{
                    top: 216px;
                }
                &.btn2{
                    top: 365px;
                }
            }
        }


    }
}