@import '../mixins.scss';
.main-list{
    ul{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
    .main-list-item{
        margin: 0px 20px 20px 0px;
        .page{
            display: inline-block;
            border-radius: 2px;
            border: 1px solid #eee;
            transition: all 0.1s;
            cursor: pointer;
            .cover{
                position: relative;
                width: 178px;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 2px;
                .float-cover{
                    visibility: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.5);
                    z-index: 99;
                    .ul-box{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    }
                    .top-bottom{
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: #f6f4ff;
                        width: 24px;
                        height: 24px;
                        background: rgba(255,255,255,0.25);
                        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);
                        border-radius: 0 2px 0 8px;
                        cursor: pointer;
                    }
                    .delete-icon{
                        position: absolute;
                        z-index: 1;
                        display: inline-block;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        font-size: 14px;
                        color: #fff;

                    }
                }
                .current{
                    position: absolute;
                    left: 5px;
                    top: 5px;
                }
            }
            .first-card{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 281px;
                cursor: pointer;
                color: #969799;
                .content{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 72px;
                    height: 80px;
                    font-size: 12px;
                    .content-svg{
                        width: 56px;
                        height: 56px;
                        position: relative;
                        top: auto;
                        left: auto;
                        font-size: 50px;
                        color: #EBEDF0;
                    }
                }
            }
            .dec{
                margin: 5px 0;
                .cover-title{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 178px;
                    .title{
                        padding: 5px 0 1px 8px;
                        //width: 95px;
                        width: 100%;
                        font-size: 14px;
                        color: #555;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .flag-sys{
                        border: 1px solid #ebedf0;
                        color: #c8c9cc;
                        background-color: #f7f8fa;
                        padding: 2px 4px;
                        margin: 5px 8px;
                        font-size: 12px;
                        line-height: 12px;
                        border-radius: 2px;
                    }
                }
                .cover-date{
                    padding-left: 8px;
                    font-size: 12px;
                    color: #999;
                    margin-top: 5px;
                    .icon-clock{
                        width: 10px;
                        height: 10px;
                        margin-right: 6px;
                        font-size: 10px;
                    }
                }
            }
            .bottom-button{
                width: 100%;
                ul{
                    display: flex;
                    width: 100%;
                    line-height: 28px;
                    border-top: 1px solid #eee;
                    padding: 0px;
                    li{
                        height: 28px;
                        flex: 1;
                        cursor: pointer;
                        .btm-button{
                            display: inline-block;
                            text-align: center;
                            width: 100%;
                            color: #555;
                            &:hover{
                                color: $BaseColor;
                            }
                        }
                        &.bdr{
                            border-right: 1px solid #eee;
                        }
                    }
                }
            }
            &:hover{
                border-radius: 2px;
                box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1);
                .float-cover{
                    visibility: visible;
                }
            }
        }
        .new-cover{
            .cover{
                background-size: 120px 140px;
                background-position: 50%;
            }
        }

    }
}
