.court-tag{
    box-sizing: border-box;
    padding: 6px 10px;
    min-width: 110px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    display: inline-block;
    height: auto;
    margin-right: 12px;
    font-size: 12px;
    white-space: nowrap;
    background: #f5f5f5;
    border-radius: 3px;
    cursor: default;
    opacity: 1;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    position: relative;
    margin-bottom: 10px;
}

.court-delete-icon{
    position: absolute;
    color: #c8c8c8;
    border-radius: 50%;
    font-size: 14px;
    top: -7px;
    right: -7px;
    z-index: 1;
    cursor: pointer;
}

.court-badge{
    display: inline-block;
    line-height: inherit;
    .badge-text{
        margin-left: 8px;
        display: inline-block;
        vertical-align: middle;
    }
    .badge-status{
        display: inline-block;
        width: 15px;
        height: 15px;
        box-sizing: border-box;
        border: 1px solid #333;
        background-color: #fff;
        vertical-align: middle;
    }
    &.lock{
        .badge-status{
            border: 0px;
            background-color: #5E4CFF;
        }
    }
    &.guest{
        .badge-status{
            border: 0px;
            background-color: orange;
        }
    }
    &.clean{
        .badge-status{
            border: 0px;
            background-color: #52c41a;
        }
    }
}
