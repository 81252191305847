
.file-video-wrap{
    position: relative;
    .file-video{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}