@import "../mixins.scss";
@import "./layout-icons.scss";

/**
 * 新布局
 */
.layout-wrapper {
    display: flex;
    min-width: 1440px;

}

.breadCrumb {
    padding: 11px;
    background-color: #fafafa;
}

.side-app-launcher {
    width: 135px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-negative: 0;
    flex-shrink: 0;
    //background: linear-gradient(180deg, #584CFF 0%, #8849FF 100%);
    background: linear-gradient(180deg, #5E4CFF 30%, #7A45E5 100%);

    .logo-wrapper {
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 110px;
        }
    }

    .apps-wrapper {
        flex: 1;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 1px;
            height: 8px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            background-color: #F5F5F5;
            border-radius: 10px;
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1, rgba(0, 0, 0, .2)));
        }

        .app-items {
            box-sizing: border-box;
            padding-left: 0px;

            .app-item {
                position: relative;
                margin: 4px 0;
                color: #fff;
                line-height: 40px;
                cursor: pointer;
                box-sizing: border-box;
                padding-left: 24px;

                &:hover {
                    color: #fff;
                }

                &.active {
                    background-color: #fff;
                    color: $BaseColor;
                }
            }

            .app-item-link {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                color: inherit;
                white-space: nowrap;
                transition: all 0s;
            }
        }
    }
}

.main-wrapper {
    box-sizing: border-box;
    width: calc(100% - 135px);

    //flex: 1;
    .global-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        box-sizing: border-box;
        padding: 0 20px 0 20px;
        background: #fff;
        box-shadow: 1px 1px 4px #efefef;
        z-index: 10;

        .user-wrapper {
            .dropdown-wrap {
                line-height: 32px;
            }
        }
    }

    .content-wrapper {
        display: flex;

        .side-menu {
            overflow-y: auto;
            flex-shrink: 0;
            height: calc(100vh - 64px);
            background-color: #fff;
            border-right: 1px solid #e8e8e8;

            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            &::-webkit-scrollbar {
                width: 0 !important
            }

            .second-menu {
                width: 155px;
                border: 0px;
            }
        }
    }
}

.router-view-wrapper {
    flex: 1;
    overflow-y: auto;
    flex-shrink: 0;
    height: calc(100vh - 64px);
    box-sizing: border-box;
    margin: 0px;

    &::-webkit-scrollbar {
        width: 2px;
        height: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #F5F5F5;
        border-radius: 10px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1, rgba(0, 0, 0, .2)));
    }
}

.top-search {
    width: 260px;
    height: 32px;
    background: #F1F1F1;
    border-radius: 18px;
    margin-right: 200px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;

    .top-search-input {
        border: 0px;
        outline: none;
        box-shadow: none;
        font-size: 14px;
        color: #666;
        background-color: transparent;

        .ant-select-selection {
            border: none;
            box-shadow: none;
        }
    }

    .ant-select-selection {
        background-color: transparent;
        border: 0;

    }

    &::after {
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-06/196507377434ac56af12a76c44f72de9.png");
        background-size: cover;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 15px;
    }
}

.cx-second-menu-icon {
    margin-left: -5px;
}