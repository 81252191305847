.recruit {
    .phone-wrap {
        width: 375px;
        height: 667px;
        // background: #f5f5f5;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;

        .phone-title {
            width: 375px;
            height: 65px;
            background-image: url('https://img.cxkoo.com/chengxuan/1/2022-07-05/fb89e72ff52293a7fbd3a47fdcf1e47e.png');
            background-size: cover;
            color: #000;
            font-size: 20px;
            text-align: center;
            box-sizing: border-box;
            padding-top: 24px;
        }

        .phone-img {
            width: 375px;
            position: relative;

            img {
                width: 375px;
                height: 500px;
            }

            .news {
                width: 231px;
                height: 33px;
                align-items: center;
                display: flex;
                background: rgba(0, 0, 0, 0.7);
                border-radius: 0 33px 33px 0;
                color: #fff;
                font-size: 12px;
                position: absolute;
                left: 0;
                top: 25px;

                .news-icon {
                    width: 14px;
                    height: 12px;
                    margin: 0 10px;
                    background: url('https://img.cxkoo.com/chengxuan/1/2022-07-19/1bf9d8e5287c30d7779af62798651942.png');
                    background-size: cover;
                    background-position: 50% 50%;
                }
            }

            .desc-btn {
                height: 33px;
                width: 85px;
                text-align: center;
                border-radius: 33px 0 0 33px;
                background: #FFFFFF;
                color: #FF0101;
                font-size: 15px;
                position: absolute;
                right: 0;
                top: 25px;
                line-height: 33px;
                font-weight: 600;
            }

        }

        .phone-body {
            padding: 0 20px 28px;
            border-top: 1px solid transparent;

            .item-wrap {
                margin-top: 50px;
                padding: 0 25px 25px;
                background: #fff;
                border-radius: 10px;

                .title {
                    width: 248px;
                    height: 41px;
                    background-image: url('https://img.cxkoo.com/chengxuan/1/2022-07-15/9c06a419340442988fcd0cd1c6134cc2.png');
                    background-size: cover;
                    position: relative;
                    top: -21px;
                    left: 0;
                    margin: 0 auto;
                    text-align: center;
                    color: #871D01;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 41px;
                }

                .steps {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 14px;

                    .img {
                        width: 49px;
                        height: 49px;
                        background-size: cover;
                    }

                    .step1 {
                        width: 50px;

                        .img {
                            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-15/06e40ed12465e26d180ebf41b7b18c8a.png");
                        }
                    }

                    .step2 {
                        width: 50px;

                        .img {
                            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-15/bfe9d40350ff2e564cada1a68f261595.png");
                        }
                    }

                    .step3 {
                        width: 50px;

                        .img {
                            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-15/8a9ca3969bf415304bf076f5a5ff6eb6.png");
                        }
                    }

                    .you {
                        width: 10px;
                        height: 17px;
                        background-size: cover;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-15/8e65f7ba19ab2e0d3ec8f1c63a1447e3.png");
                        transform: translateY(17px);
                    }


                }

                .steps-text {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;

                    .text {
                        color: #871D01;
                        font-size: 13px;
                        text-align: center;
                        line-height: 19px;
                        width: 78px;
                    }
                }


                .attainment {
                    display: flex;
                    justify-content: space-around;
                    text-align: center;

                    .label {
                        font-size: 15px;
                        color: #871D01;
                    }

                    .text {
                        background: linear-gradient(180deg, #FFAE3D 0%, #FF4000 100%);
                        -webkit-text-fill-color: transparent;
                        -webkit-background-clip: text;
                        color: #FEA901;
                        font-size: 30px;
                        font-weight: bold;
                        margin-top: 20px;

                    }
                }

                .record {
                    font-size: 14px;
                    color: #999;
                    text-align: center;
                }

            }

            .share-btns {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;

                .btn {
                    width: 160px;
                    height: 48px;
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-15/c9d5e6d149a79a3771b818941f5bb2ba.png");
                    background-size: cover;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    color: #871D01;
                    font-size: 19px;
                    font-weight: bold;
                    padding-bottom: 5px;
                    box-sizing: border-box;

                    .wx-ico {
                        width: 25px;
                        height: 21px;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-15/a07ee0684f2144ddcf63a675887aeebb.png");
                        background-size: cover;
                        margin-right: 10px;
                    }

                    .hb-ico {
                        width: 20px;
                        height: 20px;
                        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-15/0fd277520c2c920ea800f961cf4e063c.png");
                        background-size: cover;
                        margin-right: 10px;
                    }
                }
            }

        }
    }

    .ctrl-wrap {
        width: calc(100% - 395px);
    }
}