.card-detail{
    .card{
        width: 314px;
        box-sizing: border-box;
        height: 146px;
        position: relative;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/085542e88d388cd220b0b137dddd7458.png");
        background-size: cover;
        color: #000;
        font-weight: bold;
        padding: 17px 20px 15px 20px;
        cursor: pointer;
        border-radius: 10px;
        .title{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .txt{
                font-size: 12px;
            }
        }
        .crown{
            width: 28px;
            height: 28px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/d2f8fb6d4e1e2f60aa3240c294878ab5.png");
            background-size: cover;
            margin-right: 10px;
        }
        .type-img{
            width: 61px;
            height: 17px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/d55e2786784928cbb88948eff986cf15.png");
            background-size: cover;
            margin-right: 10px;
        }
        .opt-txt{
            font-size: 15px;
            color: #fff;
            position: absolute;
            right: 22px;
            top: 25px;
        }
        .card-no{
            font-size: 16px;
            position: absolute;
            left: 20px;
            top: 60px;
        }
        .balance{
            font-size: 12px;
            position: absolute;
            left: 20px;
            top: 90px;

        }
        .valid{
            font-size: 11px;
            color: #fff;
            position: absolute;
            left: 20px;
            bottom: 15px;
        }
        .qr-img{
            width: 39px;
            height: 39px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/edc51278d70f21d2f2b3822fd43513be.png");
            background-size: cover;
            position: absolute;
            right: 22px;
            bottom: 14px;
        }
        &.disable{
            color: #fff;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/0bee9763ccddd8728485cd3af60c151b.png");
            .crown{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/679b1d94a4e7e8318aa4217cdae99164.png");
            }
            .type-img{
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/9ec4fc63c8e2c847305b884be3e0205d.png");
            }

        }
        &.times-card{
            color: #fff;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-04-13/5bd6e8f471595fcb70ad92da86469f94.png");
            .crown{
                width: 28px;
                height: 28px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-04-13/63c4dc925336b11e39fca20a8d8c2859.png");
                background-size: cover;
                margin-right: 10px;
            }
            .type-img{
                width: 39px;
                height: 17px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-04-13/4276e24b11d339114f352ea7f743311c.png");
                background-size: cover;
                margin-right: 10px;
            }
        }
    }
    &.absolute{
        position: absolute;
    }
}
