.new-gift{
    .preview{
        position: absolute;
        left: 50%;
        margin-left: -40px;
    }
    .preview-title{
        font-size: 14px;
        color: #333;
        margin-bottom: 15px;
    }
    .preview-cont{
        .mob{
            width: 187.5px;
            height: 373px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-02-10/ed5518b5a39ad647c8bef23eae98ef84.png");
            background-size: cover;
            background-position: 50%;
            position: relative;
            overflow: hidden;
        }
        .mob-overlay{
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
        }
        .coupon-bg{
            width: 150px;
            height: 200px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-19/e22aac6ec84f1d38163cae51fbd18992.png");
            background-size: cover;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-70%);
        }
        .coupon-inner-img{
            width: 145.5px;
            height: 130.5px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-02-10/9fe66c20747091d597e8dd3864647307.png");
            background-size: cover;
            margin: 0px auto;
            margin-top: 40px;
        }
        .coupon-inner-btn{
            width: 105px;
            height: 18px;
            background: #FFD40D;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            color: #FA4C30;
            margin: 0px auto;
            margin-top: 5px;
        }
        .coupon-inner-close{
            width: 15px;
            height: 15px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-19/57c4be10583968f1ebf2f9309776e482.png");
            background-size: cover;
            position: absolute;
            left: 50%;
            margin-left: -8px;
            bottom: -25px;
        }
    }
}
