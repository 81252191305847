@import "../mixins.scss";
.ktv-btn-group{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    .ktv-btn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 10px;
    }
}

.book-icon{
    cursor: pointer;
    color: $BaseColor;
}
