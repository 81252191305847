.decorate-hot-area-image-editor__dialog {
    text-align: center;
    font-size: 12px
}

.decorate-hot-area-image-editor__dialog .header-content {
    color: #969799
}

.decorate-hot-area-image-editor__dialog .header-content .step-content {
    margin-right: 10px
}

.decorate-hot-area-image-editor__dialog .header-content .step-num {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 13px;
    text-align: center
}

.decorate-hot-area-image-editor__dialog .header-content .step-text {
    margin: 0 10px
}

.decorate-hot-area-image-editor__dialog .image-content {
    position: relative;
    margin-top: 20px;
    max-height: 420px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar{
        width:1px;
        height:8px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #F5F5F5;
        border-radius: 10px;
        display: none;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1, rgba(0,0,0,.2)));
    }

}

.decorate-hot-area-image-editor__dialog .image-content .decorate-hot-area-image-editor__dialog-image {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}
.hotarea-rnd {
    border: 1px solid #155bd4;
    background: rgba(21,91,212,.5);
    font-size: 12px;
    color: #fff;
    position: absolute;
    //width: 100px;
    //height: 100px;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    cursor: move;
    box-sizing: border-box;
    min-width: 50px;
    min-height: 50px;
}

.hotarea-rnd.disabled {
    pointer-events: none
}

.hotarea-rnd:hover .hotarea-close {
    display: block
}

.hotarea-rnd .hotarea-inner {
    position: relative;
    height: 100%
}

.hotarea-rnd .hotarea-close {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 16px;
    height: 16px;
    line-height: 15px;
    border-radius: 9px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background-color: rgba(0,0,0,.5);
    display: none;
    z-index: 2
}

.hotarea-rnd .hotarea-edit {
    position: absolute;
    bottom: 0;
    width: 32px;
    height: 20px;
    line-height: 20px;
    background: rgba(0,0,0,.5);
    z-index: 2;
    cursor: pointer
}

.hotarea-rnd .hotarea {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center
}

.hotarea-rnd .hotarea .hotarea-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.hotarea-rnd .hotarea .hotarea-text .help-icon,.hotarea-rnd .hotarea .hotarea-text .help-icon:hover {
    color: #fff;
    margin-left: 5px
}
