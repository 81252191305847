.qr-img{
    width: 100%;
    text-align: center;
    img{
        width: 200px;
        height: 200px;
        background-color: #f8f8f8;
       
    }
}


.share-qr-txt{
    margin-top: 15px;
    span{
        display: inline-block;
        vertical-align: middle;
    }

    .txt-elips{
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
