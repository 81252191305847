.syllabus-wrap{
    background: #F6F6F6;
    padding: 15px;
    display: flex;
    width: 600px;
    margin-bottom: 15px;
    .syllabus-label{
        padding-right: 20px;
    }
    .syllabus-icon{
        color: #5E4CFF;
        font-size: 20px;
        cursor: pointer;
        margin-left: 12px;
    }
}