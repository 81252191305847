.group-buying {
    .phone-wrap {
        width: 375px;
        height: 667px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            display: none;
        }
        scrollbar-width: none;
        -ms-overflow-style: none;

        img {
            width: 375px;
            height: 667px;
        }

        .ant-carousel .slick-dots li button{
            opacity: 0.8;
        }

        .ant-carousel .slick-dots li.slick-active button{
            background:#5E4CFF;
            opacity: 1;
        }

    }

    .ctrl-wrap {
        width: calc(100% - 395px);
    }
}

.group-buying-activity{
    .activty-head{
        display: flex;
        background:  #FAFAFA;
        padding: 10px 0;
        text-align: center;
    }
}