.preview-box {
    position: relative;
    .preview {
       width: 190px;
       height: 330px;
       padding: 0 20px;
       background: #f5f5f5;
       position: absolute;
       top: -100px;
       right: 20%;

        .pre-img {
            width: 150px;
            height: 104px;
            position: absolute;
            top: 40px;
            right: 20px;
        }

        .pre-bg {
            width: 150px;
            height: 279px;
            position: absolute;
            top: 40px;
            right: 20px;
            background-image: url('https://img.cxkoo.com/chengxuan/1/2022-11-16/99a2372bc5894a829bf70ff6523292f4.png');
            background-size: contain;
            background-position: 50% 50%;
        }
    }
}