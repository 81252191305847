
.searchBox{
    width: 500px;
    height: 50px;
    margin: 0px auto;
    overflow: hidden;
    box-sizing:border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    .pickup-search{
        display: flex;
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #c8c9cc;
        border-right: 0px;
        border-radius: 25px 0px 0px 25px;
        padding-left: 30px;
        outline: none;
        box-shadow: none;
        font-size: 17px;
        color: #333;
        &:focus{
            outline: none;
        }

    }
    .pick-up-btn{
        width: 100px;
        height: 100%;
        flex-shrink: 0;
        border-radius: 0px;
        font-size: 17px;

    }
}
.pick-img-cont{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    img{
        width: 208px;
    }
    .img-txt{
        font-size: 14px;
        color: #999;
        margin-top: 15px;
    }
}
