.points-data-stat{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .points-data{
        position: relative;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 0px;
        .title{
            color: #999;
            font-size: 14px;
        }
        .num{
            color: #333;
            font-weight: bold;
            font-size: 16px;
            margin-top: 20px;
        }
        &::after{
            display: block;
            content: '';
            width: 1px;
            height: 100%;
            background-color: #dedede;
            position: absolute;
            right: 0px;
            top: 0px;
        }
        &:last-child{
            &::after{
                display: none;
            }
        }
    }
}
