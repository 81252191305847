.grade-container{
    max-height: 500px;
    overflow: auto;
}

.drawer-cont{
    width: 100%;
}

.user-card-wrap{
    width: calc( 100% - 375px );
}
.user-card-tabs{
    width: 100%;
    overflow: auto;
    max-width: 100%;
}

