@import "./cx-diy-mixins.scss";
@import "../mixins";
.cx-ctrl{
    background-color: #fff;
    padding: 18px 5px;
    overflow: auto;
    &::-webkit-scrollbar{
        width:1px;
        height:8px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #F5F5F5;
        border-radius: 10px;
        display: none;
    }
    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1, rgba(0,0,0,.2)));
    }
}
.ctrl-right-title{
    margin: 0 0 16px 8px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
.cx-ctrl-panel{
    margin: 0 0 8px;
    background: #f7f8fa;
    border-radius: 2px;
}
.cx-ctrl-panel--title{
    position: relative;
    padding: 16px 8px 10px ;
    font-size: 14px;
    font-weight: 500;
    color: #323233;
    background: transparent;
    border: none;
}
.cx-ctrl-panel--box{
    padding: 0px 8px 15px 8px;
    border-bottom: none;
    background: transparent;
    .ant-form-item{
        margin-bottom: 5px;
    }
}
.cx-slider{
    height: 32px;
    background: #fff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    .cx-slider-main{
        display: flex;
        flex: 1;
        margin: 8px 14px 8px 14px;
        .ant-slider-track{
            background-color: $BaseColor;
        }
        .ant-slider-handle{
            border-color: $BaseColor;
        }
    }
}
.cx-img-list{
    background: #fff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    &:last-child{
        margin-bottom: 0px;
    }
    .cx-img-item{
        flex: 1;
        margin: 8px 14px 8px 14px;

        .ant-upload-list-picture-card-container {
            width: 70px;
            height: 70px;
        }

        .ant-upload-list-picture-card .ant-upload-list-item {
            width: 70px;
            height: 70px;
            cursor: move;
        }

        .ant-upload.ant-upload-select-picture-card {
            width: 70px;
            height: 70px;
        }

        .ant-upload{
            padding: 0;
        }
        .ant-upload-text{
            font-size: 12px;
        }
    }
    .delete-icon{
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        font-size: 16px;
        top: -8px;
        right: -8px;
        z-index: 1;
    }
    .ctrl-item-list-actions{
        position: absolute;
        top: 5px;
        left: 0px;
        width: 100%;
        height: 16px;
        text-align: right;
        i{
            cursor: pointer;
        }
    }

}

.template-box{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 33px;
    border-radius: 3px;
    padding-left: 0px;
    border: 1px solid #D8D5FF;
    overflow: hidden;
    margin-bottom: 0px;
    .temp{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: #fff;
        cursor: pointer;
        border-right: 1px solid #D8D5FF;
        .img{
            height: 14px;
        }
        &:last-child{
            border-right: 0px;
        }
        &.selected{
            background-color: #D8D5FF ;
        }
    }
}
