.cx-card{
    width: 440px;
    height: 170px;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px 2px rgba(30,30,30,0.1);
    padding: 20px 25px;
    cursor: pointer;
    .title{
        .txt1{
            font-size: 16px;
            color: #333;
            line-height: 26px;
        }
        .txt2{
            font-size: 12px;
            line-height: 24px;
            color: #888;
            margin-top: 16px;
        }
    }
    .title-img{
        width: 60px;
        height: 60px;
        background-size: cover;
        background-position: 50% 50%;
        &.wechat{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-28/d9a1312e275b56ddfdf595290be4d0e4.png");
        }
    }
    .card-btn-group{
        margin-top: 30px;
    }
}

.switch-title{
    color: #333;
    font-size: 14px;
}

.ticket-preview{
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 15px;
    .ticket-img{
        width: 100%;
    }
    .sun-code{
        width: 40%;
        position: absolute;
        left: 50%;
        bottom: 120px;
        transform: translate(-50%,0%);
    }
}
