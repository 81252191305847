.user-content {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    .card {
        width: 314px;
        box-sizing: border-box;
        height: 156px;
        position: relative;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/085542e88d388cd220b0b137dddd7458.png");
        background-size: cover;
        color: #000;
        font-weight: bold;
        padding: 17px 20px 15px 20px;
        margin: 0px auto;
        cursor: pointer;

        .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .txt {
                font-size: 12px;
            }
        }

        .crown {
            width: 28px;
            height: 28px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/d2f8fb6d4e1e2f60aa3240c294878ab5.png");
            background-size: cover;
            margin-right: 10px;
        }

        .type-img {
            width: 61px;
            height: 17px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/d55e2786784928cbb88948eff986cf15.png");
            background-size: cover;
            margin-right: 10px;
        }

        .opt-txt {
            font-size: 15px;
            color: #fff;
            position: absolute;
            right: 22px;
            top: 25px;
        }

        .card-no {
            font-size: 16px;
            position: absolute;
            left: 20px;
            top: 65px;
        }

        .balance {
            font-size: 12px;
            position: absolute;
            left: 20px;
            top: 94px;
        }

        .card_end_date {
            font-size: 12px;
            position: absolute;
            left: 20px;
            top: 114px;
        }

        .valid {
            font-size: 11px;
            color: #fff;
            position: absolute;
            left: 20px;
            bottom: 15px;
        }

        .qr-img {
            width: 39px;
            height: 39px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/edc51278d70f21d2f2b3822fd43513be.png");
            background-size: cover;
            position: absolute;
            right: 22px;
            bottom: 14px;
        }

        .card-cover {
            width: 100%;
            height: 125px;
            position: absolute;
            z-index: 5;
            left: 0px;
            top: 0px;

        }

        .prev {
            width: 22px;
            height: 40px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-08-05/d475fd405a6457feed3526ce65b12d80.png");
            background-size: 11px 20px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: rgba(0, 0, 0, .6);
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -20px;
            border-radius: 0 3px 3px 0;
            opacity: 0;
            visibility: hidden;
            z-index: 99;
        }

        .next {
            width: 22px;
            height: 40px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-08-05/d475fd405a6457feed3526ce65b12d80.png");
            background-size: 11px 20px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: rgba(0, 0, 0, .6);
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -20px;
            border-radius: 0 3px 3px 0;
            transform: rotate(180deg);
            opacity: 0;
            visibility: hidden;
            z-index: 99;
        }

        &:hover {

            .prev,
            .next {
                opacity: 1;
                visibility: visible;
            }
        }


        &.times-card {
            color: #fff;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-04-13/5bd6e8f471595fcb70ad92da86469f94.png");

            .crown {
                width: 28px;
                height: 28px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-04-13/63c4dc925336b11e39fca20a8d8c2859.png");
                background-size: cover;
                margin-right: 10px;
            }

            .type-img {
                width: 82px;
                height: 17px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2024-07-01/d2cc2955599d46db35c69d0a73f99ac9.png");
                background-size: cover;
                margin-right: 10px;
            }
        }

        &.disable {
            color: #fff;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/0bee9763ccddd8728485cd3af60c151b.png");

            .crown {
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/679b1d94a4e7e8318aa4217cdae99164.png");
            }

            .type-img {
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-06/9ec4fc63c8e2c847305b884be3e0205d.png");
            }

        }

    }
}

.user-content-wrapper {
    display: flex;
    height: 100%;
}

.user-info-wrapper {
    flex-shrink: 0;
    width: 362px;
    margin-right: 13px;
    box-sizing: border-box;
}

.user-card {
    box-sizing: border-box;
    background-color: #fff;
    line-height: 1.5;
    font-size: 14px;
    position: relative;
    border: 1px solid #e8e8e8;

    .user-card-head {
        background-color: #F6F5FF;
        padding: 0px 24px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
        border-bottom: 1px solid #e8e8e8;
        min-height: 48px;
        margin-bottom: -1px;
    }

    .user-card-head-wrapper {
        display: flex;
        align-items: center;
    }

    .user-card-head-title {
        padding: 12px 0;
        font-size: 14px;
    }

    .user-card-body {
        box-sizing: border-box;
        padding: 0px;

        &.inner {
            padding: 24px 0px;
        }
    }

    .user-info {
        box-sizing: border-box;
        padding: 0px 13px;
        margin-bottom: 0px;

        .user-info-li {
            padding: 14px 11px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px dashed #efefef;

            .left {
                color: #333;
            }

            .right {
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .icon-pencil {
                width: 18px;
                height: 18px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2021-10-29/0b9c1eb389a2dcc3cbb5d991b6502c38.png");
                background-size: cover;
                background-position: 50% 50%;
                cursor: pointer;
            }

            &:last-child {
                border-bottom: 0px;
            }

            .user_photo {
                .ant-upload-list-picture-card-container {
                    width: 60px;
                    height: 60px;
                }

                .ant-upload-list-picture-card .ant-upload-list-item {
                    width: 60px;
                    height: 60px;
                    cursor: move;
                }

                .ant-upload.ant-upload-select-picture-card {
                    width: 60px;
                    height: 60px;
                }

                .ant-upload{
                    padding: 0;
                }
                .ant-upload-text{
                    font-size: 12px;
                }
            }

        }
    }

    .ant-form-item {
        margin-bottom: 15px;
    }
}

.overview-panel-wrapper {
    display: flex;
    flex: 1;
    padding: 0px;
    text-align: center;

    .panel-item {
        display: flex;
        flex: 1;
        padding: 0px;
        text-align: center;
        border-right: 1px solid #efefef;

        .column {
            display: inline-block;
            text-align: left;
            margin: 0px auto;

            .title {
                color: rgba(0, 0, 0, 0.45);
                font-size: 14px;
            }

            .value {
                margin-top: 10px;
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                font-weight: bold;
            }
        }
    }

    &.fx-panel {
        .panel-item {
            height: 70px;

            .column {
                .value {
                    margin-top: 20px;
                }
            }
        }
    }
}

//用户储值卡详情
.card-bg {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(90deg, rgba(238, 195, 127, 1) 0%, rgba(250, 220, 173, 1) 100%);
    padding: 20px;

    .left {
        .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .txt1 {
                font-size: 18px;
                font-weight: bold;
                color: #000;
                line-height: 30px;
            }

            .type {
                width: 50px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                border: 1px solid #fff;
                border-radius: 3px;
                font-size: 12px;
                color: #fff;
                margin-left: 6px;
            }
        }

        .card_no {
            line-height: 30px;
            font-size: 14px;
            color: #333;
        }
    }

    .right {}

    &.times-card {
        background: linear-gradient(90deg, rgba(255, 185, 146, 1) 0%, rgba(255, 200, 172, 1) 100%);
    }
}

.fx-tab-cont {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px;
}

.user-tags{
    display: flex;
    flex-wrap: wrap;

    .user-tag{
        padding: 2px 10px;
        border-radius: 3px;
        border: 1px solid #d9d9d9;
        background: #fafafa;
        color: rgba(0, 0, 0, 0.65);
        margin-bottom: 5px;
        cursor: pointer;
        position: relative;

        &.active{
            border: 1px solid #5E4CFF;

            &::before{
                content:'';
                width:20px;
                height:20px;
                background-image: url('https://img.cxkoo.com/chengxuan/1/2023-07-19/b33c66df28242fb79422940672403aae.png');
                background-position: -2px -2px;
                background-size: 130% 130%;
                background-repeat: no-repeat;
                position: absolute;
                right: 0;
                bottom: 0;
                border-radius: 3px;
            }
        }
    }
}