@import "@/assets/css/mixins.scss";

.selectLink-content{
    position: relative;
    overflow: auto;
    .panel-wrap{
        .panel-title{}
        .panel-ctx{
            position: relative;
            padding: 0;
            height: 530px;
            overflow: auto;
        }
    }
}
