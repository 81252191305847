.order_sn{
    font-size: 20px;
    color: #000;
    font-weight: bold;
    line-height: 40px;
    vertical-align: middle;
    span{
        vertical-align: middle;
    }

}
.order-price{
    box-sizing: border-box;
    padding: 20px 50px;
}
.order-btn-group{
    box-sizing: border-box;
    padding: 0px 50px;
    display: flex;
}
.order-price-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:5px;
    .txt1{
        width: 200px;
        text-align: right;
        font-size: 14px;
        color: #000;
        font-weight: bold;
        line-height: 30px;
    }
    .txt2{
        width: 120px;
        line-height: 30px;
        color: #333;
        text-align: right;
        &.primary{
            color: #FF0000;
        }
    }
}
