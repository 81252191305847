$color:#5E4CFF;
.no-auth{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    padding: 0px 60px;
    .card{
        width: 375px;
        height: 560px;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        position: relative;
        padding: 79px 22px 0px 22px;
        transition: all 0.3s;
        &:hover{
            box-shadow: 0 2px 8px rgba(0,0,0,0.09);
            border-color: rgba(0,0,0,0.09);
        }
        .card-title{
            position: absolute;
            left: -1px;
            top: -1px;
            width: 192px;
            height: 42px;
            box-sizing: border-box;
            border: 1px solid $color;
            font-size: 14px;
            color: $color;
            border-radius: 0px 83px 83px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .card-logo{
            width: 100%;
            text-align: center;
            img{
                width: 67px;
            }
        }
        .card-desc{
            font-size: 14px;
            color: #748297;
            text-align: left;
            line-height: 30px;
            margin-top: 40px;
        }
        .card-btn{
            width: 200px;
            height: 50px;
            position: absolute;
            left: 50%;
            margin-left: -100px;
            bottom: 60px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border: 1px solid $color;
            font-size: 14px;
            cursor: pointer;
            background-color: $color;
            color: #fff;
            &.ghost{
                color: $color;
                background-color: transparent;

            }
        }
    }
}
.auth{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 20px;
    .card{
        width: 350px;
        height: 485px;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        position: relative;
        padding: 42px 22px 0px 22px;
        transition: all 0.3s;
        .card-title{
            position: absolute;
            left: -1px;
            top: -1px;
            width: 172px;
            height: 42px;
            box-sizing: border-box;
            border: 1px solid $color;
            font-size: 14px;
            color: $color;
            border-radius: 0px 83px 83px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.full{
                background-color: $color;
                color: #fff;
            }
        }
        .card-desc{
            font-size: 14px;
            color: #748297;
            text-align: left;
            line-height: 30px;
            margin-top: 40px;
        }
        .mini-code{
            width: 100%;
            text-align: center;
            img{
                width: 240px;
                height: 240px;
            }
        }
        .form-item{
            width: 100%;
            display: flex;
            justify-content:flex-start;
            align-items: center;
            margin-bottom: 10px;
            .fi-name{
                width: 90px;
                text-align: right;
                color: #748297;
                flex-shrink: 0;
                font-weight: bold;

            }
            .form-control{
                margin-left: 10px;
                color: #718299;

            }
        }
        .card-btn{
            width: 180px;
            height: 45px;
            position: absolute;
            left: 50%;
            margin-left: -90px;
            bottom: 45px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border: 1px solid $color;
            font-size: 14px;
            cursor: pointer;
            background-color: $color;
            color: #fff;
            &.ghost{
                color: $color;
                background-color: transparent;
            }
        }
        .card-btn-group{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            position: absolute;
            left: 0px;
            bottom: 45px;
            padding: 0px 22px;

            .card-btn{
                position: relative;
                width: 140px;
                left: 0px;
                bottom: 0px;
                margin-left: 0px;
            }
        }
    }
}
