@mixin Icon($url,$width,$height) {
    display: inline-block;
    width: #{$width}px;
    height: #{$height}px;
    background-image: url($url);
    background-size: cover;
    background-position: 50% 50%;
    vertical-align: middle;
  }

  .open-mp-box{
    height: 100%;
    position: relative;
    .footer-btns{
        position: absolute;
        left: 0;
        bottom: 0;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        text-align: center;
        z-index: 1;
        box-shadow: 0px -1px 40px 2px rgba(0, 0, 0, 0.1);
    }
  }
.open-mp{
    background: #fff;
    position: relative;
    height: 100%;
    overflow: auto;
    .banner-box{
        width: 100%;
        position: relative;
        .banner-bg{
            display: block;
            width: 100%;
            height: auto;
        }

        .banner-txt{
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
            text-align: center;

            .txt1{
                font-size: 38px;
                font-weight: 500;
            }

            .txt2{
                font-size: 16px;
            }
        }

    }

    .open-serve {
        height: 430px;
        padding: 0 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .serve-item {
            flex: 1;
            height: 300px;
            box-sizing: border-box;
            padding: 58px 42px 0;
            background: #F3F2FF;
            border-radius: 10px;
            position: relative;
            transition: height .3s ease-in-out;
    
            .icon1 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/c2e3e41323079cc363d3e10c89259948.png', 64, 73);
            }
    
            .icon2 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/20dae010a3952ccecf11046687661d26.png', 64, 65);
            }
    
            .icon3 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/4758514e153f6486e4f6432584e0daed.png', 77, 65);
            }
    
            .icon4 {
                @include Icon('https://img.cxkoo.com/chengxuan/1/2022-12-09/245c7c1de053ac676e89ff7edc933a86.png', 64, 66);
            }
    
            &:hover {
                height: 360px;
                padding-top: 88px;
                color: #fff;
                background: #6236FF;
            }
        }
    }

    .open-api {
        background: #F5F5F5;
        
        .api-wrap {
            padding: 0px 100px 40px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
    
            .api-col {
                width: 32%;
                height: 120px;
                background: #FFFFFF;
                border-radius: 10px;
                padding: 24px 0;
                display: flex;
                margin-top: 40px;
                box-sizing: border-box;
    
                .left {
                    width: 68px;
                    text-align: center;
    
                    .icon {
                        display: inline-block;
                        width: 34px;
                        height: 34px;
                        background-size: cover;
                        background-position: 50% 50%;
                        vertical-align: middle;
                    }
                }
    
                &:hover {
                    box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .process {
        padding: 50px 100px 130px;
    
        .row {
            display: flex;
            justify-content: center;
            align-items: flex-end;
    
            .icon1{
                @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/199a6e8aaeabbf1e99b4e154c00ad852.png",41,49);
            }
            .icon2{
                @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/66721c24de3d4548027bf35f33a0d0f2.png",41,48);
            }
            .icon3{
                @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/e50475e4aee089bdebddc86fa9e46be4.png",41,35);
            }
            .icon4{
                @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/aed7a6408251ac877b755f199734eb9a.png",48,48);
            }
    
            .you-icon{
                @include Icon("https://img.cxkoo.com/chengxuan/1/2022-12-09/4ad55238e00a1f232a8bfa71632bc4c7.png",48,21);
                margin: 0 38px;
                position: relative;
                top: -12px;
            }
        }
    
    }

   
   
}
.open-modal{
    .modal-body{
       font-size: 16px;
       line-height: 2;
       color: #000;
       padding-bottom: 40px;

       .row{
        display: flex;
        padding: 10px 0;
        .row-label{
            width: 160px;
            text-align: right;
            padding-right: 10px;
        }
        .link{
            font-size: 14px;
            width: 350px;
        }
       }
    }
}
