.error-bg{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 360px;
    }
    .txt{
        font-size: 16px;
        color: #666;
        margin-top: 40px;
    }
}

