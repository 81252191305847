.area-modal-wrapper{
    height: 320px;
   .ant-form-item-control-wrapper {
       width: 100%;
    }
    .area-form-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 20px !important;
    }
    .ant-form-item-children {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .area-modal-province {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 164px;
        height: 39px;
    }
    .down {
        font-size: 12px;
        margin-left: 3px;
    }
    .footer-occupy {
        height: 53px;
    }
    .footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 53px;
        padding: 10px 24px;
        background: #fff;
    }
    .province-dropdown{
        margin-left: 10px;
    }
}
.city-menu {
    display: inline-block;
    max-height: 230px;
    overflow: hidden;
    overflow-y: auto;
}



