@import "../mixins.scss";
.finance-data-cont{
    width: 100%;
    padding: 20px 0px;
    box-sizing: border-box;
    background-color: #fafafa;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.data-finance-item{
    display: flex;
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px 0px 10px 30px;
    position: relative;
    .txt1{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: #000;
        line-height: 17px;
        .question{
            width: 13px;
            height: 13px;
            cursor: pointer;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/f748db0ced5db54faeed21e5b220df52.png");
            background-size: cover;
            background-position: 50%;
        }
    }
    .chart-num{
        font-size: 22px;
        line-height: 34px;
        margin-top: 10px;
        margin-bottom: 5px;
        font-weight: bold;
        color: $BaseColor;

    }
    .txt2{
        font-size: 14px;
        color: #858B9C;
    }
    &::after{
        display: block;
        content: '';
        width: 1px;
        height: 100%;
        background-color: #c5c5c5;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    &:last-child{
        &::after{
            display: none;
        }
    }
    &.no-border{
        &::after{
            display: none;
        }
    }
}
