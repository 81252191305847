.grade-head {
    width: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    background-color: #fafafa;

    .grade-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .head-title {
            font-size: 20px;
            font-weight: bold;
            margin-right: 20px;
        }

        .head-tag {
            border-radius: 26px;
            padding: 2px 12px;
        }
    }

    .grade-head-content {
        padding-bottom: 20px;

        .head-line {
            display: flex;

            .head-item {
                width: 25%;
                margin-top: 30px;
            }
        }
    }
}

.sign-code {
    display: flex;

    .sign-code-left {
        width: 284px;
        margin-right: 20px;

        .code-container {
            width: 284px;
            height: 400px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-07/983a11c128d6d0c60b713d3c118c42c9.png");
            background-size: cover;
            background-position: 50% 50%;
            position: relative;

            .code-img{
                width: 140px;
                height: 140px;
                position: absolute;
                left: 72px;
                top: 100px;

                img{
                    width: 100%;
                    height: 100%;
                    background: #F5F5F5;
                }

                .code-store{
                    font-size: 14px;
                    position: absolute;
                    bottom: -30px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            
        }
    }

    .sign-code-right {
        flex: 1;
    }
}

.leave-container {
    .leave-head {
        padding: 15px 20px;

        .leave-title {
            font-size: 22px;
            font-weight: bold;
            margin-right: 15px;
        }
    }

    .leave-label{
        text-align: right;
        padding-right: 20px;
    }
}

.leave-apply-imgs{
    display: flex;
    flex-wrap: wrap;

    .leave-apply-img{
        padding: 15px;
        width: 150px;
        height: 150px;
        border-radius: 10px;
        border: 1px solid #eee;
        margin: 0 20px 20px 0;
        img{
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}