$borderColor: #eeeeee;
$layoutBg:#f9f9f9;
$BaseColor:#5E4CFF;
$navBgColor:#171e49;
$menuSelectedBgColor:#f0f4fc;
$greenColor:#88d219;
$redColor:#FF0000;
$yellowColor:#ffb205;
$primary-color:#FF0000;
p{
    margin-bottom: 0px;
}
.clearfix{
    *zoom:1;
}
.clearfix::after{
    display: block;
    content: " ";
    height:0;
    visibility:hidden;
    clear: both;
}

ol,ul,dl {
    list-style: none
}
img{
    flex-shrink: 0;
}


.fl{
    float: left;
}
.fr{
    float: right;
}
.bdbox{
    box-sizing: border-box;
}
.cursor{
    cursor: pointer;
}
.cursor-move{
    cursor: move;
}
.block{
    display: block;
}
.inline{
    display: inline;
}
.inline-block{
    display: inline-block;
}
.ft-bold{
    font-weight: bold;
}

.flex{
    display: flex !important;
}
.flex1{
    flex: 1;
}
.flex-shrink0{
    flex-shrink: 0;
}
.flex-wrap{
    flex-wrap: wrap;
}
.flex-nowrap{
    flex-wrap: nowrap;
}
.justify-start{
    justify-content: flex-start;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}
.justify-around{
    justify-content: space-around;
}
.justify-end{
    justify-content: flex-end;
}
.align-start{
    align-items: flex-start;
}
.align-center{
    align-items: center;
}
.align-end{
    align-items: flex-end;
}
.flex-column{
    flex-direction: column;
}
.mg0{
    margin: 0px;
}
.mg5{
    margin: 5px;
}

.mg10{
    margin: 10px;
}

.mg15{
    margin: 15px;
}
.mg20{
     margin: 20px;
}

.mgt0{
    margin-top: 0px !important;
}
.mgt5{
    margin-top: 5px;
}

.mgt10{
    margin-top: 10px !important;
}

.mgt15{
    margin-top: 15px;
}
.mgt20{
    margin-top: 20px !important;
}
.mgt25{
    margin-top: 25px !important;
}
.mgt30{
    margin-top: 30px;
}
.mgt40{
    margin-top: 40px;
}
.mgt50{
    margin-top: 50px;
}
.mgt60{
    margin-top: 60px;
}
.mgb0{
    margin-bottom: 0px;
}
.mgb5{
    margin-bottom: 5px !important;
}

.mgb10{
    margin-bottom: 10px !important;
}

.mgb15{
    margin-bottom: 15px !important;
}
.mgb20{
    margin-bottom: 20px;
}
.mgb25{
    margin-bottom: 25px;
}
.mgb30{
    margin-bottom: 30px;
}
.mgb35{
    margin-bottom: 35px;
}
.mgb40{
    margin-bottom: 40px;
}

.mgl0{
    margin-left: 0px !important;
}
.mgl5{
    margin-left: 5px;
}

.mgl10{
    margin-left: 10px;
}

.mgl15{
    margin-left: 15px;
}
.mgl16{
    margin-left: 16px;
}
.mgl20{
    margin-left: 20px !important;
}
.mgl24{
    margin-left: 24px;
}
.mgl30{
    margin-left: 30px;
}
.mgl40{
    margin-left: 40px;
}
.mgl50{
    margin-left: 50px;
}
.mgl60{
    margin-left: 60px;
}
.mgl70{
    margin-left: 70px;
}
.mgl80{
    margin-left: 80px;
}
.mgl90{
    margin-left: 90px;
}
.mgl100{
    margin-left: 100px;
}


.mgr0{
    margin-right: 0px;
}
.mgr5{
    margin-right: 5px;
}

.mgr10{
    margin-right: 10px;
}

.mgr15{
    margin-right: 15px;
}
.mgr20{
    margin-right: 20px;
}

.pd0{
    padding: 0px;
}
.pd5{
    padding: 5px;
}
.pd10{
    padding: 10px;
}
.pd15{
    padding: 15px;
}
.pd20{
    padding: 20px;
}
.pd30{
    padding: 30px!important;
}

.pdt0{
    padding-top: 0px;
}
.pdt5{
    padding-top: 5px;
}
.pdt10{
    padding-top: 10px;
}
.pdt15{
    padding-top: 15px;
}
.pdt20{
    padding-top: 20px;
}
.pdt40{
    padding-top: 40px;
}
.pdt50{
    padding-top: 50px;
}

.pdb0{
    padding-bottom: 0px;
}
.pdb5{
    padding-bottom: 5px;
}
.pdb10{
    padding-bottom: 10px;
}
.pdb15{
    padding-bottom: 15px;
}
.pdb20{
    padding-bottom: 20px;
}
.pdb25{
    padding-bottom: 25px;
}
.pdb30{
    padding-bottom: 30px;
}
.pdb35{
    padding-bottom: 35px;
}
.pdb40{
    padding-bottom: 40px;
}
.pdb45{
    padding-bottom: 45px;
}
.pdb50{
    padding-bottom: 50px;
}

.pdl0{
    padding-left: 0px;
}
.pdl5{
    padding-left: 5px;
}
.pdl10{
    padding-left: 10px;
}
.pdl15{
    padding-left: 15px;
}
.pdl20{
    padding-left: 20px;
}

.pdl25{
    padding-left: 25px;
}

.pdr0{
    padding-right: 0px;
}
.pdr5{
    padding-right: 5px;
}
.pdr10{
    padding-right: 10px;
}
.pdr15{
    padding-right: 15px;
}
.pdr20{
    padding-right: 20px;
}






.w100{
    width: 100px;
}
.w150{
    width: 150px;
}
.w200{
    width: 200px;
}
.w300{
    width: 300px;
}
.w400{
    width: 400px;
}
.w500{
    width: 500px;
}
.w600{
     width: 600px;
}
.w650{
    width: 650px!important;
}
.w700{
    width: 700px;
}
.w800{
     width: 800px;
}
.fz12{
    font-size: 12px;
}
.fz13{
    font-size: 13px;
}
.fz14{
    font-size: 14px;
}
.fz15{
    font-size: 15px;
}
.fz16{
    font-size: 16px;
}
.fz17{
    font-size: 17px;
}
.fz18{
    font-size: 18px;
}
.fz19{
    font-size: 19px;
}
.fz20{
    font-size: 20px;
}
.fz22{
    font-size: 22px;
}
.fz26{
    font-size: 26px;
}
.fz30{
    font-size: 30px;
}
.fz36{
    font-size: 36px;
}
.scale-08{
    transform: scale(0.8);
}

.min-h100{
    min-height: 100px;
}
.min-h200{
    min-height: 200px;
}
.min-h300{
    min-height: 300px;
}
.min-h400{
    min-height: 400px;
}

.w100{
    width: 100%;
}
.h100{
    height: 100%;
}
.lh20{
    line-height: 20px;
}
.lh25{
    line-height: 25px;
}
.lh30{
    line-height: 30px !important;
}
.lh35{
    line-height: 35px;
}
.lh40{
    line-height: 40px ;
}
.lh45{
    line-height:45px;
}

.text_link{
    color: $BaseColor;
    cursor: pointer;
}

.tac{
    text-align: center;
}
.tal{
    text-align: left;
}
.tar{
    text-align: right;
}

.small-help-text{
    font-size: 12px;
    color: #5E4CFF;
}

.fw-400{
    font-weight: 400;
}
.fw-500{
    font-weight: 500;
}

.color-000{
    color: #000;
}

.color-999{
    color: #999;
}
.color-primary{
    color: $BaseColor;
}
.bg-fafafa{
    background-color: #fafafa;
}
.radius-3{
    border-radius: 3px;
}

.hide-max{
    display: none !important;
}

.label-bg{
    background-color: #e9e9e9;
    padding: 15px;
}

.bg-p8{
    background-color: #f6f6f6;
    padding: 8px;
    border-radius: 3px;
}

.table-bg-unset{
    background: unset!important;
    &:hover{
        background: unset!important;

        td{
            background: unset!important;

        }
    }
    
    td{
        vertical-align: top;
    }
  
}

.space-wrap{
    white-space: pre-wrap;
}


.float-modal{
    position: relative;
}


.small-modal:hover + .data-modal{
   display: block;
}

.data-modal{
    display: none;
    border: 1px solid #999;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px 15px;
    position: absolute;
    top:-50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10;
}