.collect-likes {
    .phone-wrap {
        width: 375px;
        height: 667px;
        background: #f5f5f5;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar{
            display: none;
        }
        scrollbar-width: none;
        -ms-overflow-style: none;

        .phone-title {
            width: 375px;
            height: 65px;
            background-image: url('https://img.cxkoo.com/chengxuan/1/2022-07-05/fb89e72ff52293a7fbd3a47fdcf1e47e.png');
            background-size: cover;
            color: #000;
            font-size: 20px;
            text-align: center;
            box-sizing: border-box;
            padding-top: 24px;
        }

        .phone-img {
            width: 375px;
            position: relative;

            img {
                width: 375px;
                height: 667px;
            }

            .phone-btn {
                width: 290px;
                height: 60px;
                background-image: url('https://img.cxkoo.com/chengxuan/1/2022-07-06/07e485e67047bd4b6a5e40b7604d5c26.png');
                background-size: cover;
                position: absolute;
                bottom: 20px;
                left: 42px;
                color: #FD7201;
                font-size: 25px;
                text-align: center;
                line-height: 50px;
                font-weight: 600;
            }
        }

        .phone-body {
            padding: 0 20px 26px;

            .title {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
            }

            .item-wrap{
                position: relative;
                background: #fff;
                border-radius: 10px;
                padding: 40px 20px 20px;
                margin-top: 50px;

                .item-title{
                    width: 225px;
                    height: 46px;
                    left: 56px;
                    position: absolute;
                    top: -26px;
                    color: #fff;
                    font-size: 19px;
                    line-height: 46px;
                    background-image: url('https://img.cxkoo.com/chengxuan/1/2022-07-06/7ec530b8295a3f1f2e166e2483d46e88.png');
                    background-size: cover;
                    text-align: center;
                }

                .time-wrap{
                    color: #FEA901;
                    font-size: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 500;
                    .time-num{
                        width: 47px;
                        height: 54px;
                        background: #FEA901;
                        border-radius: 3px;
                        text-align: center;
                        line-height: 54px;
                        color: #fff;
                        margin: 0 8px;
                    }

                }

                .ranking-title{
                    color: #999;
                    font-size: 14px;
                    text-align: center;
                }
                .primary{
                    display: flex;
                    padding-top: 28px;

                    .item{
                        height: 160px;
                        text-align: center;
                        padding-top: 15px;
                        border-radius: 10px;

                        .hat1-icon{
                            display: inline-block;
                            width: 34px;
                            height: 21px;
                            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-06/40ebcd8d4463c4ed94d551062951f2d8.png");
                            background-size: cover;
                            background-position: 50% 50%;
                            vertical-align: middle;
                        }

                        .hat2-icon{
                            display: inline-block;
                            width: 42px;
                            height: 25px;
                            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-06/7070d428e3e5f86e6746dd240af95e44.png");
                            background-size: cover;
                            background-position: 50% 50%;
                            vertical-align: middle;
                        }

                        .hat3-icon{
                            display: inline-block;
                            width: 34px;
                            height: 21px;
                            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-06/91106a225c3db5baeb12d272348e6fc4.png");
                            background-size: cover;
                            background-position: 50% 50%;
                            vertical-align: middle;
                        }

                        .avatar {
                            width: 56px;
                            height: 56px;
                            border-radius: 50%;
                            overflow: hidden;
                            margin: -5px auto 0;
    
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                            }
                        }

                        .user-name {
                            font-size: 13px;
                            padding: 5px 0;
                        }
                        .zan{
                            font-size: 14px;
                        }
    
                        &.item1 {
                            background: linear-gradient(180deg, #F3F8FD 0%, #FFFFFF 100%);
                            width: 32%;
    
                            .avatar {
                                border: 2px solid #C6D3E2;
                            }
                        }
    
                        &.item2 {
                            margin-top: -13px;
                            background: linear-gradient(180deg, #FFF9E9 0%, #FFFFFF 100%);
                            width: 36%;
    
                            .avatar {
                                width: 69px;
                                height: 69px;
                                border: 2px solid #FFCC44;
                            }
                        }
    
                        &.item3 {
                            width: 32%;
                            background: linear-gradient(180deg, #FCF3E8 0%, #FFFFFF 100%);
    
                            .avatar {
                                border: 2px solid #DCB079;
                            }
                        }
                    }
                }
            }
        }
    }

    .ctrl-wrap {
        width: calc(100% - 395px);
    }
}