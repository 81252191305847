.group-panel{
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px 15px 20px 15px;
    position: relative;
    .delete-icon{
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        font-size: 16px;
        top: -8px;
        right: -8px;
        z-index: 1;
    }
}
