.search-phone {
    width: 375px;
    min-height: 550px;
    margin: 0 30px 0 50px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    background: #F5F5F5;
  

    .phone-title {
        width: 375px;
        height: 65px;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-07-05/fb89e72ff52293a7fbd3a47fdcf1e47e.png");
        background-size: cover;
        color: #000;
        font-size: 20px;
        text-align: center;
        box-sizing: border-box;
        padding-top: 24px;
    }

    .search {
        height: 33px;
        background: #FFFFFF;
        border-radius: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        padding: 0 3px 0 15px;
        color: #999;
        font-size: 15px;

        .search-icon {
            width: 15px;
            height: 15px;
            background-image: url("https://img.cxkoo.com/chengxuan/3/2021-10-09/140588f046d1411be27b6a461483ba1e.png");
            background-size: cover;
            margin-right: 8px;
        }

        .search-btn {
            width: 57px;
            height: 28px;
            background: #FF0000;
            border-radius: 18px;
            color: #fff;
            text-align: center;
            line-height: 28px;
        }
    }

    .dele-icon{
        width: 18px;
        height: 20px;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-10-09/f9b9a12db60848cc3bb108ce4fcd34bb.png");
        background-size: cover;
    }

    .record_wrap{
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .record{
            font-size: 13px;
            padding: 4px 12px;
            background: #E5E5E5;
            color: #484848;
            margin: 0 10px 10px 0;
            border-radius: 18px;

            &.hot{
                background: #fff;
            }
            &.active{
                color: #f00;
                span::before{
                    width: 11px;
                    height: 15px;
                    content: '';
                    background-image: url("https://img.cxkoo.com/chengxuan/1/2022-12-01/0c8aa54e30b8f8364f851f82536304c1.png");
                    background-size: cover;
                    display: inline-block;
                    margin-right: 5px;
                }
            }
        }
    }
}
.hot-icon{
    width: 13px;
    height: 17px;
    background-image: url("https://img.cxkoo.com/chengxuan/1/2022-12-01/0c8aa54e30b8f8364f851f82536304c1.png");
    background-size: cover;
    display: inline-block;
    margin-right: 5px;
}