.fx-radio-panel{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
    background-color: #fafafa;
    margin-right: 15px;
    &:last-child{
        margin-right: 0px;
    }
    .fx-radio-title{
        font-size: 18px;
        color: #000;
        font-weight: bold;
        line-height: 40px;
    }
    .fx-radio-desc{
        font-size: 14px;
        color: #999;
        line-height: 25px;
    }
}

.diy-fx-phone{
    width: 375px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #f5f5f5;
    position: relative;
    .phone-title{
        width: 100%;
        height: 65px;
        background-image: url("https://img.cxkoo.com/chengxuan/1/2022-02-28/ea28d84e317b8041c3e722fd76e68dfa.png");
        background-size: cover;
        position: relative;
        z-index: 1;
        color: #fff;
        .title-txt{
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-size: 20px;
            padding-top: 15px;
        }
    }
    .phone-angle{
        width: 100%;
        height: 65px;
        background-color: #000;
        top: 0px;
        left: 0px;
        position: absolute;
    }
    .phone-inner-bg{
        width: 100%;
        height: calc(100% - 65px);
        background-size: cover;
        background-position: 50%;
        position: relative;

    }
    .phone-inner-poster{
        width: 100%;
        box-sizing: border-box;
        position: relative;
        .poster-img{
            width: 100%;
        }
        .qr-code{
            width: 90px;
            height: 90px;
            position: absolute;
            //left: 142px;
            //bottom: 50px;
            left: 0px;
            top: 0px;
            background-size: cover;
            background-position: 50%;
            cursor: move;
            &.radius{
                border-radius: 50%;
            }
        }
        .qr-code-txt{
            font-size: 12px;
            color: #fff;
            position: absolute;
            cursor: move;
            //left: 144px;
            //bottom: 30px;
            left: 0px;
            top: 0px;
            width: 300px;

        }
        .user-info{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            left: 0px;
            top: 0px;
            cursor: move;
            .avatar{
                width: 45px;
                height: 45px;
                background-size: cover;
                background-position: 50%;
                flex-shrink: 0;
                margin-right: 10px;
                &.radius{
                    border-radius: 50%;
                }
            }
            .nickname{
                font-size: 15px;
                color: #fff;
                font-weight: bold;
                flex-shrink: 0;

            }
        }
    }
    &.poster{
        min-height: 400px;
        overflow: visible;
    }
    &.recruit{
        min-height: 615px;
    }

}
.diy-fx-ctrl{
    width: calc(100% - 400px)
}
.custom-form{
    .custom-form-item{
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;
        border: 1px dashed #eee;
        border-radius: 5px;
        box-sizing: border-box;
        padding-left: 20px;
        margin-bottom: 15px;
        position: relative;
        .form-item-label{
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            box-sizing: border-box;
        }
        &:last-child{
            margin-bottom: 0px;
        }
        .custom-delete-icon{
            position: absolute;
            background-color: #fff;
            border-radius: 50%;
            font-size: 16px;
            top: -8px;
            right: -8px;
            z-index: 1;
            cursor: pointer;
        }

    }
}
.recruit-ad{
    width: 100%;
    height: 210px;
    background-size: cover;
    background-position: 50%;
    position: relative;
}
.recruit-form-cont{
    width: 100%;
    min-height: calc(100% - 195px);
    background-color: #fff;
    border-radius: 15px 15px 0px 0px;
    //position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 2;
    padding: 15px 21px 0px 21px;
    box-sizing: border-box;
    .title{
        font-size: 25px;
        line-height: 35px;
        color: #000;
        font-weight: bold;
    }
    .sub-title{
        font-size: 15px;
        line-height: 21px;
        color: #000;
        margin-top: 12px;
    }
    .form-inner{
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 85px;
        .form-item{
            width: 100%;
            height: 56px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #f5f5f5;
            .fi-name{
                height: 100%;
                font-size: 14px;
                line-height: 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-shrink: 0;
                width: 70px;
                margin-right: 15px;
                .fi-txt{
                    position: relative;
                }
                &.required{
                    .fi-txt{
                        &::before{
                            display: block;
                            content: '*';
                            font-size: 15px;
                            color: #FF0000;
                            position: absolute;
                            left: -12px;
                            top: 0px;
                        }
                    }
                }
            }
            .form-control{
                position: relative;
                display: flex;
                flex: 1;
                height: 100%;
                justify-content: flex-start;
                align-items: center;
                .input,input{
                    font-size: 14px;
                    color: #000;
                }
                .placeholder{
                    color: #999;
                    font-weight: normal;
                    font-size: 14px;
                }
                .mobile-btn{
                    width: 93px;
                    height: 26px;
                    background: #EFB861;
                    border-radius: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #fff;
                    position: absolute;
                    right: 0px;
                }

            }
            &:last-child{
                //border-bottom: 0px;
            }
        }

    }
    .bottom-inner{
        width: 100%;
        position: absolute;
        background-color: #fff;
        left: 0px;
        bottom: 0px;
        box-sizing: border-box;
        padding-bottom: 8px;
        height: 80px;
        .agree-file{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            line-height: 16px;
            color: #818181;
            .check{
                width: 15px;
                height: 15px;
                background-image: url("https://img.cxkoo.com/chengxuan/1/2022-02-28/f3977bcb59a9aa1555a815070eae1189.png");
                background-size: cover;
                margin-right: 7px;
            }
            .file-txt{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .txt1{
                    color: #EFB861;
                    margin-left: 3px;
                }
            }

        }
        .bottom-btn{
            width: 332px;
            height: 40px;
            background: #000000;
            border-radius: 22px;
            margin: 0px auto;
            margin-top: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: #EFB861;
        }
    }
}
.data-check-inner{
    width: 100%;
    padding: 20px 20px 0px 20px ;
    box-sizing: border-box;
    background-color: #fafafa;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
