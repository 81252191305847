@import "./cx-form.scss";
@import "./diy-phone.scss";
@import "./cx-diy-mixins.scss";
@import "./cx-diy-module.scss";
.editor-layout{
    .header{
        padding: 0 10px;
    }
    .editor-logo{
        width: 120px;
        height: 31px;
        margin: 16px 28px 16px 0;
        float: left;
        line-height: 31px;
        text-align: center;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }
    .ant-tabs-bar{
        margin: 0 0 8px 0;
    }
}
.transparent-bg {
    background: transparent !important;
}
.plugin-usage-tip {
    font-size: 12px;
    color: #999;
    border-left: .2rem solid #dfe2e5;
    padding: .25rem 0 .25rem 0.5rem;
}
.shortcut-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60px ;
    width: 100%;
    margin-bottom: 5px;
    background-color: #f5f8fb;
    color: #393e46;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s;
    box-sizing: border-box;
    padding-top: 5px;
    &:disabled {
        cursor: not-allowed;
    }
    &:hover{
        background-color: #f2f2fb;
    }
    .shortcut-icon {
        padding: 4px;
        font-size: 18px !important;
    }
    .shortcut-txt {
        margin-left: 0px !important;
    }
    .comp-icons{
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin-bottom: 2px;
        &.icon-setting{
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/98a9d6cf31eb098b3baa645b6f05b193.png");
            width: 20px;
            height: 20px;
        }
        &.icon-tupian{
            width: 23px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/ea70de1dcbfbfdd32b0cb76963210c22.png");
        }
        &.icon-fenlei{
            width: 31px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/9c6d1aada3cade0e65973593817a31e1.png");
        }
        &.icon-lunbo{
            width: 22px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-12-26/ea7b24ad80feba6bb1bbd0124a206300.png");
        }
        &.icon-lunbo-card{
            width: 40px;
            height: 15px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-12-26/8d511fd72955aa889769968ca2b532b5.png");
        }
        &.icon-lunbo-3d{
            width: 25px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/319ef119161a052a8f456524974a1fc5.png");
        }
        &.icon-biaoti{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/56bb05104af6e1cd489422034e3e340b.png");
        }
        &.icon-requ{
            width: 23.5px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/abeb5950716ecc27b3547e90a4118c95.png");
        }
        &.icon-xiantiao{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/72616c3d78356815c7fa513eb6c041ec.png");
        }
        &.icon-gonggao{
            width: 24.7px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/fe6b851277cc204d3e380025cccc9c43.png");
        }
        &.icon-shangping{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/69aad8c861e5b05154378de9321641c2.png");
        }
        &.icon-search{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/8e29d8efa3cd2614cee20dcb9c42ec0c.png");
        }
        &.icon-hangye{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-11-25/69aad8c861e5b05154378de9321641c2.png");
        }
        &.icon-count-down{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2021-12-13/8af944354af4162ae61e41c4bc5a016f.png");
        }
        &.icon-ktv{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-04/8c9a3f4a1052d278743ad7e783dbce83.png");
        }
        &.icon-time-discount{
            width: 21px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-01-18/469f034e8a92df7736a568d370b287e1.png");
        }
        &.icon-coupon{
            width: 21px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-12-05/77209497f94daae5397497caa91893e4.png");
        }
        &.icon-court{
            width: 28px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-04-02/dce7663523ad548de6fe358b39cf09d1.png");
        }
        &.icon-note{
            width: 22px;
            height: 22px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-10-19/67271528a698ae9dae54c8c2f26401c3.png");
        }
        &.icon-web{
            width: 24px;
            height: 22px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-10-25/34f1893b587fa048317d4654c70a4879.png");
        }
        &.icon-kecheng{
            width: 22px;
            height: 22px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-29/180c28741e369fb76c5bde6583bb1a12.png");
        }
        &.icon-laoshi{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-29/336fa5c047e995ae26ad14a93d811903.png");
        }
        &.icon-pingtuan{
            width: 23px;
            height: 22px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-09-02/47089629fdfbce54cf9a8931b750d7e5.png");
        }
        &.icon-shequntuan{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2023-01-13/958407df0a3708867c7c8e4db7e61e0d.png");
        }
        &.icon-jifenduihuan{
            width: 16px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2023-03-31/fc67ec434b45829733002802353e1393.png");
        }
        &.icon-danxuan{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/6df149a0f3652f19e534989a31513762.png");
        }
        &.icon-duoxuan{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/89e0f0e9dc6a6f6c337d2e08fbd17652.png");
        }

        &.icon-tpdanxuan{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/d4e1bb634d1f54febb18e3f6a956a299.png");
        }

        &.icon-tpduoxuan{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/bf17a5c825890014876d1f0d9beb1aaf.png");
        }

        &.icon-xiala{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/b8d00b5ee60054d3efc0b97d6e126958.png");
        }

        &.icon-danxiangtiankong{
            width: 24px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/241cf12eb1da42423ec0186e7fc18bac.png");
        }

        &.icon-duoxiangtiankong{
            width: 24px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/e7495d7f50821733548716741df10595.png");
        }

        &.icon-henggang{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/c578c8cfa064b08ddfe1878c6249e86f.png");
        }

        &.icon-jianda{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/b81f5c9ee2a4d2365bcdd0e410a69129.png");
        }

        &.icon-xingming{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/574ad93387005182e39b2b2c5eddb096.png");
        }

        &.icon-dianhua{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/25bccca89dd51e7baecee07feffd41bb.png");
        }

        &.icon-weixin{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/6df149a0f3652f19e534989a31513762.png");
        }

        &.icon-qq{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/ab1bdc025d9650f04c30990cb7e84d8d.png");
        }

        &.icon-youxiang{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/732a40019f256d25302270cf8967ec2e.png");
        }

        &.icon-dizhi{
            width: 20px;
            height: 20px;
            background-image: url("https://img.cxkoo.com/chengxuan/1/2022-06-26/cd0e3f767e071fe7180d8fab9f6d8d1e.png");
        }




    }
}

